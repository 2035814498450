//Looper
.looper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  
    span {
      width: 100%;
      height: 100%;
      display: block;
      background-color: var(--white);
      background-clip: content-box;
      box-sizing: border-box;
      padding: 10%;
      transform: scale(0);
      border-radius: 100%;
  
      animation-name: dotLoad;
      animation-duration: 2s;
      animation-iteration-count: infinite;
  
      @for $n from 2 to 5 {
        &:nth-of-type(#{$n}) {
          animation-delay: #{calc($n / 10)}s;
        }
      }
  
      @keyframes dotLoad {
        0% {
          transform: scale(0);
        }
  
        10% {
          transform: scale(1.2);
        }
  
        50% {
          transform: scale(1);
        }
  
        70% {
          transform: scale(1);
        }
  
        80% {
          transform: scale(1.2);
        }
  
        100% {
          transform: scale(0);
        }
      }
  
    }
  }