.project-banner {
    position: fixed;
    background: var(--base-900);
    width: 100%;
    height: 60vh;
    top: 0;
    box-sizing: border-box;
    padding: 5% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  
    &-content{
  
      max-width: 1280px;
  
      &>div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10%;
        max-width: 1000px;
        justify-items: center;
    
        &>section {
          box-sizing: border-box;
    
          h5 {
            margin-bottom: 5px;
          }
    
          &>div {
            margin-bottom: 15px;
          }
        }
      }
    }
  
  
    .project_category {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 5px;
  
      p {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  
    .project_long_desc {
      font-size: 1em;
    }
  
    &::before {
      content: '';
      background: var(--base-900);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  
    h1 {
      text-align: center;
      color: var(--white);
      margin: 2% auto;
      font-size: clamp(80px, 13vw, 130px);
      font-weight: 100;
    }
  
    img {
      position: absolute;
      top: -5%;
      left: -10%;
      width: 120%;
      height: 120%;
      max-width: none;
      object-fit: cover;
      opacity: 0.35;
      user-select: none;
      transition: transform 0.2s ease-out;
      user-select: none;
      pointer-events: none;
    }
  
    &>* {
      position: relative;
      color: var(--white);
    }
  }