#spheros {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  
    img {
      position: absolute;
      transition:
        transform 0.35s ease-out,
        opacity 0.3s ease-out,
        top 1s cubic-bezier(.24, .01, .08, 1),
        left 1s cubic-bezier(.24, .01, .08, 1),
        width 1s cubic-bezier(.24, .01, .08, 1);
  
      opacity: 0;
  
      &.grow {
        opacity: 1;
      }
    }
  
  
  }