form.contactForm {

    position: relative;
  
    input {
      display: block;
      background: transparent;
      box-sizing: border-box;
      width: 100%;
  
      &[type='text'] {
        cursor: text;
      }
  
      &[type="submit"] {
        font-weight: bold;
        padding: 0;
      }
    }
  
    fieldset {
      transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    }
  
    &.sent {
      fieldset {
        opacity: 0;
        pointer-events: none;
        transform: translate3d(0px, -100px, 0px);
      }
  
      .confirmMessage {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
      }
    }
  
    .confirmMessage {
      line-height: 10px;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      transform: translate3d(0px, 100px, 0px);
      transition: opacity 0.5s cubic-bezier(.06, .44, .18, .98), transform 0.5s cubic-bezier(.06, .44, .18, .98);
  
      a {
        pointer-events: auto;
        color: var(--primary-500);
      }
  
      svg {
        fill: var(--primary-500);
      }
    }
  
    textarea {
      border: none;
      background: transparent;
      resize: none;
    }
  }