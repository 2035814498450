.project-thumbnail {
    position: relative;
    display: inline-block;
    transition: transform 0.6s ease-out, opacity 0.3s ease;
    z-index: 1;


    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      background-color: var(--white);
      transition: transform 0.2s ease, width 0.2s ease, border-radius 0.2s ease, height 0.2s ease, padding 0.2s ease, clip-path 0.2s ease, background 0.2s ease;
    }
  
    &,
    * {
      cursor: pointer;
    }
  
    @include ctashadow();
  
    &>div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
  
      &>.thumb {
        opacity: 1;
      }
    }
  
    .project-desc {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      transition: transform 0.2s ease-out, opacity 0.2s ease;
      opacity: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      cursor: pointer;
  
      padding: 1em;
      gap: 6px;
      box-sizing: border-box;
      filter: drop-shadow(0px 3px 4px #00000012);
  
      &>* {
        overflow: visible;
        line-height: initial;
        margin:0;
        box-sizing: border-box;
        padding: 5px 15px;
        width: fit-content;
        opacity: 0;
        transition:
          clip-path 0.3s ease-out,
          opacity 0.2s ease-out,
          transform 0.3s ease-out;
        clip-path: inset(0 100% 0 0);
        transform: translate3d(-10px, 0, 0);
        border-radius: 2.5px;
        border: none;
        background: var(--white);
        color: var(--primary-500);
        letter-spacing: 0.1px;
      }
  
      h2 {
        font-size: 2em;
        padding-bottom: -10px;
      }
  
  
  
      small {
        font-weight: 500;
      }
  
  
    }
  
    .overlay {
      border-radius: var(--border-radius);
  
      .thumb {
        position: absolute;
        transition: transform 0.2s ease-in-out;
        transition-delay: 0.1s;
        max-width: none;
        max-height: none;
        height: 100%;
      }
  
      .vimeo {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        background: var(--base-500);
  
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        iframe {
          align-self: flex-start;
          flex-shrink: 0;
          max-width: none;
          height: 100%;
          width: 100%;
          background: transparent;
        }
      }
    }
  
    .move {
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
  
  
  
    .thumb {
      width: 100%;
      transition: transform 0.35s ease-out, opacity 0.35s ease-out;
      vertical-align: top;
      position: relative;
      min-height: 31vh;
      object-fit: cover;
    }
  
    .iframePlaceholder img {
      height: 100%;
      object-fit: cover !important;
    }
  
    @media(hover: hover) {
      &:hover {
        z-index: 3;
  
        &>.thumb {
          opacity: 0;
        }
  
        header.project-desc>* {
          clip-path: inset(0 0 0 0);
          transform: translate3d(0, 0px, 0);
          opacity: 1;
  
          & *:nth-child(2) {
            transition-delay: 0.2s;
          }
  
          &:last-child {
            transition-delay: 0.2s;
          }
        }
  
  
      }
    }
  
    @media(hover:none) {
      .thumb {
        opacity: 0;
      }
    }
  
  
  }