.vimeo {

    @include maxWidth();
    position: relative;
    width: 100%;
    display: block;
    flex-basis: auto !important;
    margin-bottom: 20px;
    overflow: hidden;
    aspect-ratio: 16/9;
  
    iframe {
      width: 100%;
      height: 100%;
      position: relative;
      pointer-events: auto;
    }
  
    video{
      background: var(--base-900) url("../../assets/loader.gif") center center no-repeat;
    }
    .iframePlaceholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      pointer-events: auto;
      cursor: pointer;
      background-color: var(--base-900);
      background-clip: content-box;
  
  
      @media(hover:hover) {
        &:hover {
          .playIco {
            transform: translate(-50%, -50%) scale(1.1);
          }
  
          .picPlaceholder {
            transform: scale(1.1);
          }
        }
      }
  
  
      section {
        display: block;
        width: 100%;
        height: 100%;
        //background: var(--base-500);
        position: relative;
      }
  
      .picPlaceholder {
        width: 100%;
        max-height: 100%;
        box-sizing: border-box;
        transform: scale(1);
        transition: transform 0.5s ease-out;
        cursor: pointer;
        opacity: 0.5;
      }
  
      .playIco {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        position: absolute;
        width: 70px;
        height: 70px;
        object-fit: contain;
        pointer-events: auto;
        transition: transform 0.3s ease-out;
        cursor: pointer;
      }
  
  
    }
  }