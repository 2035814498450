#loader {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 998;
    overflow: auto;
    pointer-events: none;
  
    &>span {
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--primary-500);
      position: absolute;
      clip-path: inset(0% 0% 0% 0%);
  
      &:last-of-type {
        background-color: var(--white);
      }
    }
  }