body {
  margin: 0;
  counter-reset: step;
}

* {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


h1,
h2,
h3,
h4 {
  margin: 0;
}


object {
  box-sizing: border-box;
}


/*———————————————————————————————————————————————-*/


.projectContainer {
  background: linear-gradient(0deg, var(--bkg) 90%, #ffffff00 91%);
  padding-top: 50vh;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  color: black;
  margin: 0;
  height: auto;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-clip: content-box;
}

.projectContainer>* {
  background-color: var(--bkg);
}

.projectContainer #VIEWER {
  box-sizing: border-box;
  padding: 0 10vw;
}

.step {
  position: relative;
}

.step::before {
  counter-increment: step;
  content: counter(step);
  font-size: 2em;
  position: absolute;
  color: var(--mainColor);
  z-index: 0;
  top: -80%;
  margin-left: -2%;
  opacity: 0.5;
  display: none;
}

.step span {
  z-index: 1;
  position: relative;
  font-family: murmure;
}

.step::after {
  content: '';
  width: 40px;
  height: 6px;
  display: none;
  margin-bottom: 20px;
  background: var(--mainColor);
}

.connector {
  height: 50px;
  display: block;
  border-right: solid;
  margin: 10%;
  border-image-source: url("../assets/connector.gif");
  border-image-slice: 1 3;
}

.windowHeight {
  height: 100vh;
}

.stickToRight {
  margin-right: -20%;
}

.stickToLeft {
  margin-left: -20%;
}

/*———————————————————————————————————————————————-*/

#cardboard {
  position: fixed;
  display: flex;
  height: 50vh;
  width: 100vw;
  overflow: hidden;
  padding: 0 10vw;
  box-sizing: border-box;
  align-items: center;
  color: var(--mainColor);
  justify-content: space-around;
  z-index: -1;
  flex-direction: column;
}


#cardboard>section {
  width: max-content;
}

#cardboard h1 {
  width: fit-content;
}

.bullet {
  border: solid 1px;
  border-radius: 100%;
  width: 0.7em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 0.7em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullet span {
  width: 50%;
  height: 50%;
  display: block;
  background: #000;
  margin: 0;
  border-radius: 100%;
  padding: 0;
}

body[data-skin="dark"]{

  .article{

    &-table{
      table{
        border-color: var(--base-700);

        td,
        th {
          border-color: var(--base-700);

          &:first-of-type {
            background-color: var(--base-800);
          }
        }
      }
    }
  }
}


.article {

  &-margin-bottom {
    margin-bottom: 2em;
  }

  &-table {
    overflow-x: auto;
    width: 100%;

    $radius: 10px;

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      border: none;
      border-radius: $radius;

      ul{
        padding-left: 1rem;
      }

      tr {

        &:last-of-type {
          td {
            &:first-of-type {
              border-bottom-left-radius: $radius;
            }
            &:last-of-type{
              border-bottom-right-radius: $radius; 
            }
          }
        }

        th {
          &:first-of-type {
            border-top-left-radius: $radius;
          }

          &:last-of-type {
            border-top-right-radius: $radius;
          }
        }

        td {
          vertical-align: text-top;
        }

        td,
        th {
          padding: 1em;
          border: solid 1px var(--base-100);

          &:first-of-type {
            background-color: var(--base-50);
          }
        }
      }

    }


  }
}

/*———————————————————————————————————————————————-*/

.light {
  color: #000;
}

.light>#cardboard p,
.light ul {
  color: #6f6f6f;
}

.light>#cardboard .separator,
.light .bullet span {
  background: #6f6f6f;
}

.dark {
  color: #FFF;
}

.dark>#cardboard p,
.dark ul {
  color: #FFF;
}

.dark>#cardboard .separator,
.dark .bullet span {
  background: #FFF;
}


#desc {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 5% 0;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  text-align: left;
}

/*———————————————————————————————————————————————-*/

.blank {
  width: 100%;
  height: 20rem;
  display: block;
  max-height: 200px;
  box-sizing: border-box;
}

.sblank {
  width: 100%;
  height: 5rem;
  display: block;
  box-sizing: border-box;
}

.sblank.grad,
.blank.grad {
  background: linear-gradient(0deg, var(--bkg) 0%, #ffffff00 95%);
}

.flexblank {
  flex-basis: 50%;
}

/*———————————————————————————————————————————————-*/

.story {
  padding: 4% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 5%;

  &.dark h2 span {
    background-image: url("../assets/leaf_dark.svg");
  }

  h2 {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 1em;
      height: 1em;
      display: block;
      background-image: url("../assets/leaf.svg");
    }
  }

  &>section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 5%;

    p {
      width: 50%;
    }
  }
}




/*———————————————————————————————————————————————-*/

.app {
  background-color: #FFF;
  border-radius: 1em;
  box-shadow: 0 2px 3px 0px rgb(0 0 0 / 20%);
}

.roundpic {
  border-radius: 0.3em;
}

.quote {

  margin: 100px 0;
  position: relative;

  &::before {
    left: -40px;
    top: -30px;
    content: "“";
    font-family: "murmure";
  }

  &::after {
    content: "”";
    font-family: "murmure";
    right: -50px;
  }

  &::before,
  &::after {
    font-size: 8em;
    position: absolute;
    opacity: 0.5;
    height: -webkit-fill-available;
  }

  p {
    float: left;

    &::before {
      content: '— ';
    }
  }

}


.pix {
  image-rendering: -webkit-optimize-contrast;
}


.parallax {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  left: -10vw;
  width: 110vw;
  height: 80vh;
  left: 50%;
  position: relative;
  transform: translateX(-50%);


  &-picture {

    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;

    img {
      transition: transform 0.1s ease;
      transform: translate3d(0, -15%, 0);
      object-fit: cover;
      max-width: none;
      width: 160%;
      object-fit: cover;
    }

  }

  &-header {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: flex-start;
    padding: 0 calc(100px + 5%);

    header {
      box-sizing: border-box;
      padding: 2rem 3rem;
    }
  }
}

/*———————————————————————————————————————————————-*/

.backpage {
  background-color: #1a1a1a;
  color: white;
  overflow-x: hidden;
  max-width: 100vw;
}

.backpage>* {
  padding: 3em 10vw;
}

.backpage::before,
.backpage::after {
  content: '';
  background: #1a1a1a;
  display: block;
  width: 110%;
  height: 120px;
  border-radius: 100%;
  margin-left: 50%;
}

.backpage::before {
  transform: translate(-50%, -50%);
}

.backpage::after {
  background: var(--bkg);
  transform: translate(-50%, 50%);
}

.gradBackpage {
  background-image: linear-gradient(180deg, transparent 0%, #1a1a1a 95%);
}

.nopattern {
  background-image: none !important;
}

.imgWrapper {
  display: flex;
  max-width: 1920px;
  margin-bottom: 10px;
  justify-content: center;

  img {
    max-width: 100%;
  }
}

.backdrop {
  &.center {
    &::before {
      top: 0;
    }
  }

  &.fill {
    &::before {
      height: 100%;
    }
  }

  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 120vw;
    left: 50%;
    transform: translateX(-50%);
    height: 90%;
    top: 0%;
    display: block;
  }
}

#project .projectWrapper>div.article {
  display: block;
}

.article {

  box-sizing: border-box;
  padding-top: 100px;
  padding-right: 8%;
  padding-left: 8%;
  width: 100%;
  @include maxWidth();

  box-sizing: border-box;
  display: block;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  max-width: 1280px;
  margin: auto;

  &.plain {
    max-width: none;
    padding-left: 0;
    padding-right: 0;

    .compress {
      padding-left: 12%;
      padding-right: 12%;
    }
  }

  &.appear {
    opacity: 1;
  }

  &.spaced {
    margin-bottom: 3rem;
  }

  &.backdrop {
    margin-top: 10%;
  }

  img {
    object-fit: contain;
  }

  &>* {
    position: relative;
  }

  iframe {
    flex-shrink: 0;
    position: relative;
    flex-basis: auto !important;
  }

  .tall {
    max-height: 70%;
  }

  

  &>.body {
    img {
      max-width: 100%;
    }

    display: flex;
    column-gap: 1em;
    overflow-x: inherit;

    &>* {
      flex-basis: 50%;
    }

    &>section {
      flex-grow: 1;
      position: relative;
    }

  }

  .vimeo {
    iframe {
      background: black;
    }
  }

  &>.header {
    margin-bottom: 30px;
  }

  .padded {
    box-sizing: border-box;
    padding: 5%;

    &.small {
      padding: 2.5%;
    }
  }

  .half {
    width: 50%;
  }

  .third {
    width: calc(100%/3);
  }

  .gridlayout {
    justify-items: center;
    align-items: center;
    display: grid;
    grid-template-columns: 50% 50%;
    flex-basis: 100%;

    &.tall>* {
      max-height: 70%;
    }

    &>* {
      box-sizing: border-box;
      padding: 1%;
    }
  }


}

.tableGroup {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
  gap: 50px;
  width: 100%;
}


.guidestyle {

  table {
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 80px;
    width: 100%;

    img {
      max-width: 300px;
    }

    thead {
      border-bottom: solid 1px #444d6660;
    }

    td {
      box-sizing: border-box;
      padding: 15px 20px;

    }


    .packColor {
      margin-top: 10px;
      box-sizing: border-box;
      padding: 0 20px;
      justify-content: center;
      flex-wrap: wrap;
      gap: 5px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minMax(20px, 1fr));

      .colorswatch {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        height: auto;

        &.lightStroke {
          border: solid 1px #252a39;
        }
      }
    }
  }
}



.persona {
  border-radius: 10px;
  display: flex;
  margin-top: 100px;
  max-width: 100vw;
  width: max-content;
  flex-shrink: 0;

  &-slider {

    display: inline-flex;
    overflow: scroll;
    width: max-content;
    left: 0;
    padding: 0px 8% 50px 8%;
    box-sizing: border-box;

    &>div {
      display: flex;
      flex-direction: row;
      gap: 50px;
      cursor: ew-resize;
    }


    &::-webkit-scrollbar {
      display: none;
    }

  }

  &-subtitle {
    position: relative;
    line-height: 1.2em;
    display: flex;
    flex-direction: row;
    gap: 10px;

    svg {
      width: 28px;
      fill: var(--primary-500);
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 20px;
    box-sizing: border-box;
    padding: 15px 0px;
  }

  header {
    display: flex;
    min-width: 270px;
    justify-content: center;
    max-width: 320px;
    box-sizing: border-box;
    padding: 15px 20px 40px;

    background-size: cover;

    div.persona-biography {
      width: 100%;
      margin-top: 130px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    h4 {
      font-size: 32px;
      align-self: center;
      text-align: center;
    }

    picture {
      img {
        border-radius: 100%;
        max-width: 100%;
      }

      width: 200px;
      height: 200px;
      object-fit: contain;
      position: absolute;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        width: 110%;
        height: 110%;
        display: block;
        border-radius: 100%;
        border: solid 1px var(--primary-500);
        position: absolute;
      }
    }

    background-color: var(--base-500);
    color: var(--white);
    border-radius: 10px 0px 0 10px;

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 10px 0;
        gap: 10px;

        &,
        strong {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        strong {
          gap: 5px;
        }

        svg {
          fill: var(--white);
        }

      }
    }

  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;

    .persona-details {
      max-width: 700px;

      ul {
        list-style: square;
      }

      li {
        margin: 5px 0;
      }
    }

    .persona-interperso {
      ul {
        padding: 0;
        list-style: none;
        color: var(--primary-500);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
          &:not(:last-of-type) {
            display: flex;
            flex-direction: row;
            align-items: center;

            &::after {
              margin: 0 10px;
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              background: var(--primary-500);
              border-radius: 2px;
            }
          }
        }
      }
    }

    .persona-subtitle {
      margin-bottom: 10px;
    }

    hr {
      border: solid 1px var(--primary-50);
    }
  }

}


.pointer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-items: start;
  align-items: center;
  height: fit-content;

  &[data-theme="dark"] {
    span {
      background-color: var(--white);

      &::before {
        background: var(--white);
      }
    }
  }

  &[data-side="right"] {
    grid-template-columns: 2fr 1fr;

    span {
      &::before {
        left: auto;
        right: 0;
      }
    }
  }

  small {
    margin-top: 10px;
  }

  span {
    box-sizing: border-box;
    padding: 0 8px;
    position: relative;
    width: 100%;
    height: 0.5px;
    background-color: black;
    background-clip: content-box;
    display: block;
    max-width: 250px;
    min-width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: black;
    }
  }
}


/*———————————————————————————————————————————————-*/

.infobox {
  min-width: 3em;
  min-height: 4em;
  background: #F4F4F4;
  border-radius: 1em;
  box-sizing: border-box;
  box-shadow: 0 0 7px 0px rgb(0 0 0 / 20%) inset;
  display: grid;
  grid-template-columns: 3em 1fr;
  padding: 3%;
  column-gap: 3%;
  align-items: center;

  span {
    height: 3em;
    flex-basis: 3em;
    box-sizing: border-box;
    background-size: 70%;
  }


}


/*———————————————————————————————————————————————-*/


.hoverpop {
  transition: transform 0.2s ease-out, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

}

.window.hoverpop:hover {
  box-shadow: 12px 4px 15px -3px rgb(0 0 0 / 20%);
}



/*———————————————————————————————————————————————-*/


/**
*----------LAOS----------
**/



/*———————————————————————————————————————————————-*/

#guidesheet {
  display: flex;
  flex-direction: column;
  align-items: center;

  .infobox {
    width: 100%;
    flex-basis: auto;
    margin-bottom: 3%;
  }

  .body {
    width: 80%;
    margin: auto;
    column-gap: 5%;
  }
}


.guidesheet {
  display: block;

  .app {
    width: 3em;
    display: block;
  }

  .header {
    display: flex;
    direction: column;
    align-items: center;
    column-gap: 3%;
  }

  table {
    width: 100%;
    margin-top: 10%;
    row-gap: 2%;
    border: none;
    border-collapse: collapse;

    td {
      padding-left: 2%;
      padding-right: 2%;
      font-size: 100%;
    }
  }

}



.guide_prim {
  text-align: center;
  padding: 1% 0;
}

.guide_case {
  text-align: center;
  padding: 2% 0;
}

.guide_bkg {
  /*padding: 4% 0;*/
  height: 3em;
  text-align: center;
}

.guidesheet .compare>*:last-child {
  text-align: right;
}

.guidesheet .compare>*:first-child {
  text-align: left;
}

table .tspace td {
  padding: 5px 0;
}

#guide_popup {
  padding: 0;
  content: " ";
  border: double 2px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ff0073, #2094ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  height: 100%;
  overflow: initial;
}

.white {
  color: white;
}

.darkmode {
  background-color: #222222;
  border-radius: 0;
}

.darkmode.end {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.darkmode.begin {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.sanguin.top:first-of-type,
.whitebkg.top:first-of-type {
  border-top-left-radius: 0.5em;
}

.sanguin.top:last-of-type,
.whitebkg.top:last-of-type {
  border-top-right-radius: 0.5em;
}

.sanguin.bottom:first-of-type,
.whitebkg.bottom:first-of-type {
  border-bottom-left-radius: 0.5em;
}

.sanguin.bottom:last-of-type,
.whitebkg.bottom:last-of-type {
  border-bottom-right-radius: 0.5em;
}

.guidesheet .animated {
  border-style: solid;
  border-color: #717070;
  border-width: 1px;
  border-radius: 0.5em;
}

.guidesheet .card td {
  height: 130px;
  background-position: left;
}

.guidesheet .firegrad section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.cartography {

  overflow: hidden;

  &.active {
    .cartography-label {
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    cursor: grab;

    .hints {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.drag {
    img {
      transition: none;
    }
  }

  .hints {
    font-size: 0.8em;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    margin: 10px;
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
    transition: transform, opacity, 0.25s ease-out;
  }

  img {
    transition: transform 1s ease;
    pointer-events: none;
    user-select: none;
    user-drag: none;

  }

  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9;

  border: solid 1px var(--base-400);

  &-label {
    position: absolute;
    box-sizing: border-box;
    padding: 15px 20px;
    background: #0f0f2fc0;
    font-weight: 500;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: 1;
    color: var(--white);
  }

}