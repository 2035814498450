.scroll-icon {


    display: block;
    position: relative;
    height: 3em;
    width: 1.5em;
    border: .1em solid var(--base-400);
    border-radius: 1em;
  
    &__wheel-outer {
      display: block;
      position: absolute;
      left: 50%;
      top: .6em;
      height: 1em;
      width: .4em;
      margin-left: -.2em;
      border-radius: .4em;
      overflow: hidden;
    }
  
    &__wheel-inner {
      display: block;
      height: 100%;
      width: 100%;
      border-radius: inherit;
      background: var(--base-400);
      animation: scroll_1 1.75s ease-in-out infinite;
    }
  
    &__dot {
      display: block;
      position: absolute;
      left: 50%;
      background: var(--base-400);
      height: .5em;
      width: .5em;
      top: .6em;
      margin-left: -.25em;
      border-radius: 50%;
      transform-origin: top center;
      backface-visibility: hidden;
      animation: scroll_2 2s ease-out infinite;
    }
  
    // General icon style
  
    &-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      bottom: 20px;
      pointer-events: none;
      width: 100%;
      gap: 10px;
      z-index: 4;
    }
  
  
    // Example 2
    @keyframes scroll_2 {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
  
      25% {
        opacity: 1;
      }
  
      75% {
        transform: translateY(.75em);
        opacity: 0;
      }
  
      100% {
        transform: translateY(0);
        opacity: 0;
      }
    }
  
  
  }