#socials {
    position: fixed;
    z-index: 9;
    bottom: 0;
    right: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  
    &>div {
      width: fit-content;
      display: flex;
      align-items: center;
  
      &:last-of-type {
        flex-direction: column;
        gap: 10px;
      }
    }
  
    .bars {
      background-color: var(--base-400);
      display: block;
  
      &.vertical {
        width: 1px;
        height: 20px;
      }
  
      &.horizontal {
        width: 20px;
        height: 1px;
        margin: 0 5px;
      }
    }
  
    @media(hover:hover) {
      .children:hover {
        .ico {
          opacity: 1;
        }
      }
    }
  
  }