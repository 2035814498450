@media only screen and (max-width: 525px){

  .cat{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      &:last-of-type{
        padding-bottom: 100px;
      }
    }

    #showreel{
      padding: 10%;
      .vimeo{ width: 100%; }
    }

    .project-thumbnail{
      box-sizing: border-box;

      .project-desc{
        h2{
          font-size: 1.5em;
        }
        & > *{
          opacity: 1;
          clip-path: inset(0 0 0 0);
        }
      }
    }

    #frameWrapper{
      h2{
        font-size: 12em;
      }
    }

    .project-banner{
      padding: 10vh 10%;
    }



    #letterbox {
      display: grid;
      justify-items: center;
      gap: 30px;

      #iframewrapper{
        width: 90vw;
        #viewshowreel{display: none;}
      }
      #tagline{ 
        font-size: 5em;
        text-align: center;
      }
      #textLetterbox{
        margin-top: 15%;
        justify-content: space-evenly;
        gap:32px;
        height: auto;

        .cta{
          min-width: 200px;
        }
        
        & > header{ 
          span{ display: none; }

          h1{ 
            font-size: 20px; 
            margin-bottom: 10px;
          }
          h1,p{
            br{ display: none;  }
          }
          h2{
            font-size: clamp(70px, 4vw, 120px); 
          }
        }

      }
    }

    #menuPanel{
       #labelContent{
          box-sizing: border-box;
          top: 0px;
          transform: translate(0px, 0px);
          flex-direction: column;
          font-size: 12px;
          margin: auto;
          & > *{margin: 0;}
          height: 100%;
          justify-content: space-evenly;
          padding: 50px 0;
       }
    }
    
    #resume{
      display: block;
    }



    .tableGroup{
      width: 100%;
    }

}
@media only screen and (max-width: 425px) {

  .quotes{
    header{
      text-align: center;
      &.flex.f-between{
        justify-content: center;
      }
    }
  }


  #letterBox {
    & > div{ top:10%; }
    img{ display: none; }
    & > section:first-child {
      width: 100%;
      padding: 2em;
      top: 4%;
    }
  }

  #viewshowreel{
    display: none;
    animation: none;
  }

  .project-banner{ 
    position: relative; 
    max-width: 100vh;
  }
  #project{
    margin: 0;
    .projectWrapper {
      padding: 10px;
      padding-top: 10%;
      float: none;
      .article{
        box-sizing: border-box;
      }
    }
  }



  #mainTitle{  display: none; }

  #menu{
    #bars.active{
      margin-top: 2vw;
    }
  }


  #mainContainer .smallFrame {
    height: calc( 100vw * (9/16) );
  }

  footer{
    padding: 0;
    flex-direction: column;
    align-items: center;

    br{ display: none; }
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap:10px;
      &  > * { margin: 5px 0; }
    }
  }

  #mainBack{ display: none; }
  #suggest > section{ height: auto; }
  #contactContainer {

    .contactForm{
      margin: 0;
      width: 100%;
    }
     &> h1 {
       font-size: 5em;
        margin-left: 40px;
        margin-top: 20%;
     }
  }

  #resume{

    &-wrapper{
      margin-top: 100px;
    }

    &-content{
      padding: 0;
      & > div{ 
        &:last-of-type, &:first-of-type{ padding: 0;}
      }
    }
  }




  /*_____________________________________________*/
  /*_____________________________________________*/
  /*_____________________________________________*/
  /*______________CASE STUDIES___________________*/
  /*_____________________________________________*/
  /*_____________________________________________*/
  /*_____________________________________________*/

  #cardboard{
    & > section{ width:100%; }
    h1{ white-space: nowrap; }
  }


  .hidmobile{ display: none; }
  .blank.mobile{ display:none; }

  .story > section{
    flex-direction: column !important;
    width: 100%;
    box-sizing: border-box;
    padding: 5vw;
    p{ width: 100% !important; }
    h2{ margin-bottom: 0 !important; }
  }

  article.persona{
    .persona-grid{
      grid-template-columns: auto;
    }
  }



  .article {
    &.spaced{ margin-bottom: 10%; }

  }

  section#diffuse_lumino::before{
    top:0;
  }



  #keyshape{ width:90%; }

  #laos_layout .body > div:not(#slicer){
    flex-direction: column;
  }


  #laos_typo .body{ flex-direction: column-reverse;  }

  #laos_sidebar .app{ width: 30%;  }
  #laos_layout{padding: 0; }

  #laos_typo .header {
    padding-left: 5%;
    font-size: 80%;
  }

  .player{
    margin-left: 0;
    width: 100%;
  }

  .guide_prim { padding: 20px 0;  }

  #Viewer{ max-height: none;}

  #mood{ height: calc(100vw*9/16); }

  #mobileexp .blank{ display: none; }

  .backpage{ padding: 3em 5vw; }

  .backpage .padded{ padding: 0;}

  /*.article .padded{ padding-left: 0; padding-right: 0; }*/

  .projectContainer #VIEWER { padding: 0; }

  #rosas_dotty{ display: none; }

  #guidesheet .infobox {  width: 100%;  }

  #guidesheet .body { width: 100% !important; margin-left: 0 !important; }

  #laos_options{
    margin-top: 5vh;
  }

  #laos_sidebar > li section {
    width: 25%;
  }

  #inspi > div:first-child{ display: none; }
  #inspi > div:last-child .moviemason{
    margin: 0;
    transform:none;
  }

  #toolslider .ts_tool{
    width: 100%;
    justify-content: space-around;
  }

  #laos_sidebar > li section{
    width:15%;
    border-radius: 20px;
  }


  #creatool{display: inline-block;}
  #creatool > div{ margin-bottom: 100px;}

  .taglabel{ flex-wrap: wrap; }

  #cinetypo .body > div:last-of-type tr > td:first-child { text-align: center; }

  #tableKino{
      border: none;
      border-radius: 10px;
      padding: 0;
  }

  #chains{
    margin-left: -10%;
    width: 120%;
  }

  #morpheus{
    width: 100%;
  }

  #influ{
    height: fit-content;
    padding: 43px 0;
  }

  #rosas .hori{
    row-gap:0;
  }

  #zenIllu{
    display: none;
  }

  #logo_container{
    transform: translateY(-50%) scale(0.5) !important;
  }


}
