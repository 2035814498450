
.tags{
  transition: transform 0.2s ease;
}

.tags:hover{
  transform: scale(1.3);
}

.one{transform-origin: 328px 326px;}
.two{transform-origin: 265px 230px;}
.three{transform-origin: 195px 456px;}
.four{transform-origin: 544px 207px;}
.five{transform-origin: 81px 113px;}

.st0{fill:none;}
.st1{fill:none;stroke:#8B4D99;stroke-width:5;}
.st2{fill:#FFFFFF;}
.st3{fill:#8B4D99;}
.st4{font-family:'noto-bold';}
.st5{font-size:21px;}
.st6{fill:none;stroke:#F7A420;stroke-width:5;}
.st7{fill:#F7A420;}
.st8{fill:none;stroke:#E72874;stroke-width:5;}
.st9{fill:#E72874;}
.st10{fill:none;stroke:#61B5E6;stroke-width:5;}
.st11{fill:#61B5E6;}
.st12{fill:none;stroke:#000000;stroke-width:5;}
.st13{fill:#010202;}
