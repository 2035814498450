:root {
    /* Color styles */
  --pure-white: hsla(0, 0%, 100%, 1);
  --white: #f5f7f9;
  
  --base-50: hsla(228, 12.2%, 92%, 1);
  --base-100: hsla(225, 12.5%, 74.9%, 1);
  --base-200: hsla(226, 11.6%, 62.7%, 1);
  --base-300: hsla(225, 14.2%, 45.7%, 1);
  --base-400: hsla(225, 21.8%, 35.1%, 1);
  --base-500: hsla(225, 50.5%, 19%, 1);
  --base-600: hsla(225, 50%, 17.3%, 1);
  --base-700: hsla(225, 50.7%, 13.5%, 1);
  --base-800: hsla(224, 50.9%, 10.4%, 1);
  --base-900: hsla(226, 51.2%, 8%, 1);
  
  --primary-50: hsla(247, 100%, 96.5%, 1);
  --primary-100: hsla(247, 100%, 88.8%, 1);
  --primary-200: hsla(247, 100%, 83.3%, 1);
  --primary-300: hsla(247, 100%, 75.9%, 1);
  --primary-400: hsla(247, 100%, 71.2%, 1);
  --primary-500: hsla(247, 100%, 63.9%, 1);
  --primary-600: hsla(247, 78.4%, 58.2%, 1);
  --primary-700: hsla(247, 56.7%, 45.3%, 1);
  --primary-800: hsla(247, 56.4%, 35.1%, 1);
  --primary-900: hsla(247, 56.2%, 26.9%, 1);
  
  --background: hsla(220, 100%, 99.4%, 1);
  --stroke: hsla(221, 100%, 96.9%, 1);
  
    //colours
    --card-bkg: #fcfdff;
    --card-stroke: #EFF4FF;
  
  
    //shapes
    --border-radius: 10px;
  
  
    //effects
    --shadow-color: 210deg 7% 65%;
    --shadow-elevation-medium:
      0.1px 0.5px 0.6px hsl(var(--shadow-color) / 0.28),
      0.1px 1.1px 1.2px -0.7px hsl(var(--shadow-color) / 0.27),
      0.3px 2.2px 2.4px -1.4px hsl(var(--shadow-color) / 0.26),
      0.6px 4.5px 4.9px -2.1px hsl(var(--shadow-color) / 0.25),
      1.2px 8.7px 9.6px -2.7px hsl(var(--shadow-color) / 0.24);

    //units
    --unit-xs: 2px;
    --unit-s: 4px;
    --unit-m: 8px;
    --unit-l: 12px;
    --unit-xl: 16px;
    --unit-2xl: 20px;
    --unit-3xl: 32px;
    --unit-4xl: 48px;
    --unit-5xl: 64px;
    --unit-6xl: 96px;
    --unit-7xl: 128px;
  
  
  }