@use '../utils';

.inspire {
    position: relative;
    padding-top: 200px;
    overflow: hidden;
    background-color: var(--white);
  
    h3 {
      text-align: right;
      font-size: utils.clamped(30px, 50px);
    }
  
    &>div {
      position: relative;
      margin-top: 70px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      height: 530px;
  
      img {
        width: 100%;
  
        &:nth-of-type(2n) {
          margin-top: 80px;
        }
      }
  
      &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(245, 247, 249, 0.00) 51.56%, var(--white) 83.85%);
      }
    }
  }