.socialsIcons {
    display: flex;
    align-items: center;
    flex-direction: row;
  
    span {
      cursor: pointer;
    }
  
    .ico {
      margin: 0px 8px;
      opacity: 0.6;
      transition: opacity 0.2s ease;
  
      @media(hover:hover) {
        &:hover {
          opacity: 1;
        }
      }
  
      span {
        width: 16px;
        height: 16px;
        display: block;
        background-color: var(--base-900);
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
  
        &[data-social="behance"] {
          mask-image: url('../../assets/icons/behance.svg');
        }
  
        &[data-social="linkedin"] {
          mask-image: url('../../assets/icons/linkedin.svg');
        }
  
        &[data-social="twitter"] {
          mask-image: url('../../assets/icons/twitter.svg');
        }
  
        &[data-social="pinterest"] {
          mask-image: url('../../assets/icons/pinterest.svg');
        }
  
        &[data-social="vimeo"] {
          mask-image: url('../../assets/icons/vimeo.svg');
        }
  
        &[data-social="artstation"] {
          mask-image: url('../../assets/icons/artstation.svg');
        }
  
        &[data-social="mail"] {
          mask-image: url('../../assets/icons/mail.svg');
        }
  
        &[data-social="instagram"] {
          mask-image: url('../../assets/icons/instagram.svg');
        }
      }
    }
  }