*.cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    text-transform: lowercase;
    gap: 5px;
    letter-spacing: 0.8px;
    position: relative;
    padding: 0px 20px;
    background: white;
    transition: background 0.2s ease, transform 0.1s ease-in-out, color 0.2s ease;
    border-radius: 2.5px;
    text-align: center;
    height: 42px;
    min-width: 140px;
    overflow: hidden;
    box-sizing: border-box;
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary-400);
      z-index: 0;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }
  
    &>* {
      position: relative;
    }
  
    &,
    * {
      color: var(--primary-500);
    }
  
    &.primary {
      font-weight: bold;
      background: var(--primary-500);
  
      &,
      * {
        color: var(--white);
      }
  
    }
  
    &.secondary {
      border: solid 1px var(--primary-500);
      background: transparent;
    }
  
  
    @media(hover:hover) {
      &:hover {
        &.secondary {
          border-color: var(--primary-400);
        }
  
        &,
        * {
          color: var(--white);
        }
  
        &::before {
          opacity: 1;
        }
  
        svg {
          fill: var(--white);
        }
      }
    }
  
  
  }