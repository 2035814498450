
.purpleBkg{
    //box-sizing: border-box;
    //padding: 80px;
    //background-image: linear-gradient(45deg, #4a3db0, #160a71);
    //color: white;
  }
  
  
  .article{
  
  
    &[data-board-name="Music"] {
      .imgWrapper{ position: relative; }
      &, & > *{
        overflow: visible;
      }
    }
  
    &[data-board-name="Meditation"] > section div:last-child .imgWrapper{ position: relative; }
  
    &[data-board-name="Diffuser"]{
      .body > div{
        &:last-of-type{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
      }
    }
    
  
  }
  
  .musicWave{
    pointer-events: none;
    position: absolute;
    width: 120vw;
    display: block;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: repeat-x;
    background-image: url('/projects/mengfeng/assets/wave_1.svg');
    background-size: 99%;
    opacity: 0.3;
    z-index: 0;
  
    animation-name: waveslide;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  
    @keyframes waveslide{
      to{ background-position-x: -121vw; }
    }
  
    &:nth-of-type(2){
      opacity: 0.2;
      animation-delay: 1.5s;
    }
  }
  
  .mengfeng-cartography{
    box-sizing: border-box;
    .cartography{
      border-color: var(--primary-100);
    }
  }
  
  #diffuse_lumino{
    &::before{
      top:-7%;
      background: linear-gradient(124.8deg, #491DBC -2.73%, #0F175F 100.08%);
    }
    color: white;
  
    & > section > div{
        &:first-of-type{ padding-top: 10%; }
        &:last-of-type{ padding-bottom: 20%; }
    }
  }
  
  #zenIllu{
  
    position: absolute;
    bottom:20%;
    width: 80%;
    left: 30%;
    z-index: 0;
  
    animation-name: sin;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  
    @keyframes sin{
      to{ transform: translateY(80px); }
    }
  }
  
  
  #pairing{
    section{
      &:nth-of-type(1){ margin-top: 5%; }
      &:nth-of-type(2){ margin-top: 10%;}
      &:nth-of-type(3){ margin-top: 15%; }
    }
  }
  