.button-icon {

    aspect-ratio: 1/1;
    height: fit-content;
    border-radius: 100vmax;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;

    &[data-state="default"] {
        transition: fill, background, 0.2s linear;
        border-color: var(--primary-500);

        svg {
            fill: var(--primary-500);
        }

        &:hover {
            background: var(--primary-500);

            svg {
                fill: var(--primary-50);
            }
        }
    }

    &[data-state="disabled"] {
        border-color: var(--primary-100);

        svg {
            fill: var(--primary-100);
        }
    }

    &[data-size="default"] {
        width: var(--unit-2xl);
    }

    &[data-size="large"] {
        width: var(--unit-5xl);
    }
}