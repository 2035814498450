@mixin frameMargin() {
  box-sizing: border-box;
  padding: 40px 45px;
  padding-right: 60px;
}

.article {

  .gallery {

    $gap: 5px;
    margin: calc($gap/2) 0;

    overflow: hidden;

    img {
      transition: transform 0.75s cubic-bezier(0.220, 0.375, 0.205, 1.000);
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      cursor: pointer;

      @media(hover:hover) {
        transform: scale3d(1, 1, 1);

        &:hover {
          transform: scale3d(1.02, 1.02, 1.02);
        }
      }
    }

    .imgWrapper {
      margin: 0;
    }

    &.split,
    &.base {
      display: grid;
      grid-gap: $gap;
      box-sizing: border-box;
      grid-auto-flow: column;
    }

    &.base {
      display: grid;
      //grid-template-columns: repeat(3, minmax(100px,1fr));
      justify-items: center;
      align-items: center;
    }

    &.split {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    &.window {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gap;
      row-gap: $gap;
      margin-bottom: $gap;
    }

    &.column {
      display: flex;
      flex-direction: column;
    }

    .master {
      grid-column: span 2;
      grid-row: span 2;

      &.vertical{
        grid-column: 1;
      }
    }


    &.split>*,
    &.base>* {
      flex: 1;
      flex-basis: 50%;
    }

    &>*,
    section {
      width: 100%;
      height: 100%;
    }

    &>section {
      border: solid 1px #ebebec;
    }

    section {
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
    }

  }
}


.fullview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999989;
  display: grid;
  grid-template-rows: 90% 10%;
  overflow-x: scroll;
  overflow-y: hidden;
  align-content: space-between;
  @include frameMargin;

  .fullview_bkg {
    background: #000000e3;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 0;
  }

  .close {
    cursor: pointer;
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    margin: 35px 30px;

    span {
      cursor: pointer;
      border-radius: 10em;
      width: 100%;
      height: 2px;
      background-color: white;
      display: block;
      position: relative;

      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-of-type {
        position: absolute;
        transform: rotate(-45deg);
      }
    }
  }

  &::-webkit-scrollbar {
    height: 0 !important;
    display: none;
  }

  &::-webkit-scrollbar {
    height: 0 !important;
    display: none;
  }

  img {
    height: 100%;
  }

  .mainframe {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 3%;
    height: 75vh;
    pointer-events: none;

    img {
      pointer-events: auto;
      position: absolute;
      cursor: ew-resize;
    }
  }

  .thumbnails {
    position: relative;
    display: flex;
    flex-direction: row;
    max-height: 25vh;
    box-sizing: border-box;
    pointer-events: none;

    img {
      pointer-events: auto;
    }

    &.center {
      justify-content: center;
    }

    &>* {
      overflow: hidden;
      margin: 0 10px;
      border-radius: 5px;

      @media(hover:hover) {
        img {
          transform: scale3d(1, 1, 1);
        }

        &:hover {
          img {
            transform: scale3d(1.05, 1.05, 1.05);
          }
        }
      }

      img {
        border-radius: 5px;
        transition: transform 0.2s ease-out;
      }
    }

  }

}


@media only screen and (max-width: 825px) {


  .gallery {
    &.split {
      display: flex;
      flex-direction: column;
      grid-auto-flow: row;
      grid-template-columns: none;
      height: -webkit-fill-available;
    }


    &.window {
      margin-bottom: 0px !important;
    }
  }

}


@media only screen and (max-width: 500px) {

  .article{

    .gallery {

      &.window {
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
      }
  
      &.window, &.split{
        grid-auto-flow: row;
        grid-template-columns: none;
      }
  
      &.base {
        display: flex;
        flex-direction: column;
        grid-auto-flow: row;
  
        .master {
          grid-column: span 1;
          grid-row: span 1;
        }
      }
    }
  }

}