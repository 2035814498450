@use '../utils';
@use '../core/fonts';

#resumeSide {

    width: calc(100vw/2.5);
    top: 0;
    position: fixed;
    @include frameMargin;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
  
  }
  
  #resume {
  
    &-grid {
      display: grid;
      grid-template-columns: 0.8fr 2fr;
      padding-right: 100px;
      padding-left: 20px;
      gap: 60px;
      margin: auto;
      
      @media only screen and (min-width: 1920px) {
        grid-template-columns: 1fr 2fr;
      }
    }
  
    &-portrait {
      position: sticky;
      left: 0%;
      top: 100px;
      width: 100%;
      max-width: 850px;
      max-height: 90vh;
      height: auto;
      aspect-ratio: 360 / 620;
      canvas {
        height: 70%;
      }
    }
  
    &-wrapper {
      margin-top: 100px;
    }
  
    &-header {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 60px;
  
      h2 {
        margin-bottom: 10px;
        @include fonts.h1;
      }
  
      p {
        margin-top: -30px;
        margin-bottom: 7px;
        color: var(--primary-500);
        font-weight: bold;
  
        .zhg {
          font-weight: bold;
        }
      }
    }
  
    &-content {
      padding-left: 0;
      width: fit-content;
      box-sizing: border-box;
      justify-content: end;
      width: 100%;
      position: relative;
  
      #signature {
        position: absolute;
        bottom: 0;
        right: 0;
      }
  
      .resumeSection {
        box-sizing: border-box;
        margin-bottom: 30px;
        transition: transform 0.6s ease-out;
        display: grid;
        grid-template-columns: minMax(100px, 200px) 3fr;
        gap: 40px;
        margin-bottom: 100px;
  
        .sectionHeader {
          position: sticky;
          top: 120px;
          height: fit-content;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 1em;
          color: var(--primary-500);
          width: 200px;
          word-break: normal;
          hyphens: manual;
        }
  
        ul {
          margin: 10px 0;
  
          li {
            margin: 6px 0;
            list-style-type: square;
  
            &::marker {
              color: var(--base-500);
            }
          }
        }
  
        .content {
          margin-bottom: 15px;
          line-height: 1.5em;
  
          &.default {
            display: grid;
            gap: 10px;
            align-items: center;
  
            &.card {
  
              &.linked {
  
                @media(hover:'hover'){
                  transition: border 0.2s ease;
                  &:hover{
                    border-color: var(--primary-400);
                  }
                }
                p {
                  color: var(--primary-500);
                  font-weight: bold;
                }
              }
  
              display: flex;
              flex-direction: column;
            }
  
            &:has(.contentIcon) {
              grid-template-columns: 1fr minmax(300px, 10fr);
            }
  
            &:not(:last-of-type) {
              span.path-stroke {
                height: 100%;
                display: block;
                width: 2px;
                background: linear-gradient(0deg, var(--primary-100) 50%, transparent 50%);
                background-repeat: repeat-y;
                background-size: 1px 9px;
                align-self: center;
                justify-self: center;
  
                animation: movebkg 20s linear infinite;
  
                @keyframes movebkg {
                  to {
                    background-position-y: calc(-1 * 9 * 20px)
                  }
                }
              }
            }
  
            .contentIcon {
              width: 30px;
              object-fit: contain;
              align-self: center;
              justify-self: center;
            }
  
            small {
              display: block;
            }
          }
  
          &.technical {
            &>* {
              margin-bottom: 15px;
              padding-top: 10px;
            }
  
            li {
              list-style: none;
              margin: 0;
  
            }
  
            ul {
              padding: 0;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 10px;
            }
          }
  
          &.language {
  
            .card {
              &>* {
                width: 100%;
              }
            }
  
            .contentHeader {
  
              padding: 8px 12px;
  
              &,
              &>span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                column-gap: 5px;
                width: 100%;
              }
            }
  
            small {
              display: flex;
              align-items: center;
              font-weight: bold;
              gap: 5px;
            }
          }
  
          &.skills {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
  
            small {
              word-wrap: unset;
              display: inline;
              padding: 5px 15px;
              border: solid 1px var(--primary-500);
              color: var(--primary-500);
              gap: 10px;
              border-radius: 15em;
            }
          }
  
          &.grid {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            gap: 10px;
          }
  
        }
  
        .contentHeader {
          font-weight: bold;
          color: var(--base-500);
  
          a {
            color: var(--primary-500);
            font-weight: bold;
  
          }
  
          .neutral {
            color: var(--base-500);
          }
        }
  
        .contentVisual {
          width: 100%;
          aspect-ratio: 280/128;
          align-self: flex-start;
        }
  
  
      }
  
  
    }
  
    &-settings {
      top: 0;
      display: flex;
      justify-content: flex-end;
      gap: 32px;
      position: fixed;
      z-index: 2;
      padding-top: 20px;
      padding-bottom: 1%;
      background: var(--white);
      pointer-events: none;
      padding-left: 6%;
      padding-right: 6%;
      box-sizing: border-box;
      width: 100%;
      margin:auto;
      left: 0;
      right: 0;
  
  
      &>div {
        pointer-events: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
  
        &:first-of-type {
          justify-self: center;
        }
  
        &:last-of-type {
          justify-self: flex-end;
        }
  
        label {
          margin-right: 0 20px;
        }
      }
  
  
      #dlresume {
        svg {
          fill: var(--primary-500);
        }
  
        &:hover {
          svg {
            fill: var(--white);
          }
        }
      }
  
      * {
        border-radius: 2.5px;
        font-weight: bold;
      }
  
      input:checked+label {
        color: var(--primary-500);
  
        &::after {
          width: 100%;
        }
      }
  
      label {
        position: relative;
        color: var(--primary-100);
        transition: color 0.2s ease;
        display: flex;
        align-items: center;
        flex-direction: column;
  
        @media(hover:hover) {
          &:hover {
            color: var(--primary-500);
          }
        }
  
        &::after {
          content: '';
          width: 0%;
          height: 2px;
          background-color: var(--primary-500);
          display: block;
          border-radius: 10em;
          transition: width 0.3s ease-out;
        }
      }
    }
  }