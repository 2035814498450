#menu {
    position: fixed;
    z-index: 99999;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    top: 25px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0 2%;
    color: var(--base-500);
    align-items: center;
    pointer-events: none;

    &>div {
        width: 100%;
        display: flex;
        align-items: center;
        pointer-events: none;
        margin: auto;
    }

    & * {
        pointer-events: auto;
    }

    #NLogo {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;

        p {
            width: max-content;
            font-size: 0.7em;
            line-height: 1.2em;
            max-width: 60vw;
        }

        svg {
            transition: fill 0.15s ease;
            fill: var(--base-500);
            height: 30px;
            overflow: inherit;

            @keyframes floatPos {
                to {
                    transform: translate3d(0, 50px, 0);
                }
            }

            @keyframes floatNeg {
                to {
                    transform: translate3d(0, -50px, 0);
                }
            }

            rect {
                transform: translate3d(0, 0px, 0);
                animation-duration: 0.8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }

        }

        @media(hover:hover) {
            &:hover {
                p {
                    color: var(--primary-500);
                }

                svg {
                    fill: var(--primary-500);

                    rect {
                        &:first-of-type {
                            animation-name: floatPos;
                        }

                        &:last-of-type {
                            animation-name: floatNeg;
                        }
                    }

                }
            }
        }

    }

    #homeButton {
        display: flex;
        flex-direction: row;

        &,
        & * {
            cursor: pointer;
        }

        user-select: none;
        align-items: center;

        svg {
            width: 24px;
            height: 24px;
            fill: var(--base-400);
            transition: fill 0.1s ease;
        }

        small {
            transition: color 0.1s ease;
            font-weight: 700;
        }
    }

    &.white {
        #NLogo {
            p {
                color: var(--white);
            }

            svg {
                fill: var(--white);
            }
        }

        .topArea {
            #bars:not(.active) {
                span {
                    background: var(--white);
                }
            }
        }

        #homeButton {
            small {
                color: white;
            }

            svg {
                fill: white;
            }
        }
    }

    .topArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        width: 100%;
        height: 30px;
        justify-content: center;
        pointer-events: none;

        &>* {
            pointer-events: auto;
        }

        #bars {
            align-self: flex-end;
            width: 40px;
            position: relative;
            transition: margin, 0.3s ease-out;
            cursor: pointer;

            &:not(.active):hover {
                .default span {
                    width: 100% !important;
                }
            }

            span {
                transition: width 0.2s ease-out, transform 0.15s ease-out;
                transform: rotate(0deg);
                display: block;
                background: var(--base-500);
                width: 100%;
                border-radius: 10em;
                //height: 2.5px;
                height: 3px;
            }

            .default {
                span {
                    &:last-of-type {
                        margin-top: 5px;
                        width: 70%;
                        margin-right: 0;
                        float: right;
                    }
                }
            }

            .close {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    position: absolute;
                    width: 0;

                    &:first-of-type {
                        transform: rotate(45deg);
                    }

                    &:last-of-type {
                        transform: rotate(-45deg);
                    }
                }
            }

            &.active {

                //margin-top: 1vw;
                //margin-left: 2vw;
                .default {
                    span {
                        width: 0%;
                    }
                }

                .close {
                    span {
                        transition-delay: 0.3s;
                        width: 70%;
                    }
                }
            }
        }
    }



    a.active {

        #windowIco {
            @include setWindowColor();
        }

    }




    #windowIco {
        padding-top: 15px;
        width: 25px;
        height: 25px;
        display: block;
        cursor: pointer;
        position: relative;

        span {
            position: relative;
            transition: background 0.2s ease;
            border-radius: 100%;
            box-sizing:
                border-box;
            padding: 1.3px;
            display: block;
            float: left;
            background: var(--base-500);
            background-clip: content-box;
            width: calc(100%/2);
            height: calc(100%/2);

            &:nth-of-type(2),
            &:nth-of-type(3) {
                border-radius: 0;
            }

            transition: padding 0.1s ease-out;

            animation-duration: 0.15s;
            animation-direction: alternate;
            animation-iteration-count: 4;
            animation-fill-mode: backwards;

            @for $i from 1 to 5 {
                &:nth-of-type(#{$i}) {
                    animation-delay: #{calc(random(5)/10)}s;
                }
            }

        }

        &:hover {
            @include setWindowColor();

            span {
                padding: 0.4px;
                animation-play-state: running;
                animation-name: wiggleOpa;
            }

        }
    }


    @keyframes wiggleOpa {
        from {
            opacity: 1;
        }

        to {
            opacity: 0.1;
        }
    }

}


#menuPanel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9999;

    img[alt='sphere'] {
        position: fixed;
        width: 40vw;
        top: -10%;
        left: -10%;
        z-index: 2;

        &:last-of-type {
            width: 40vw;
            top: 30%;
            left: 80%;
        }
    }

    &>div {
        display: block;
        position: absolute;
        z-index: 4;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &:first-of-type {
            background: var(--primary-500);
        }

        &:last-of-type {

            background: var(--white);
            //background-image: url('../assets/menubkgburn.jpg');
            background-size: cover;
            background-position: left;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0 !important;
                display: none;
            }

            &::-webkit-scrollbar {
                width: 0 !important;
                display: none;
            }

            & {
                scroll-behavior: smooth;
            }
        }

        h1 {
            font-weight: 200;
        }

        h1,
        a {
            display: inline;
            letter-spacing: normal;
            line-height: normal;
            position: relative;
            cursor: pointer;

            &.active {
                &::before {
                    content: '';
                    border-radius: 10em;
                    position: absolute;
                    bottom: 1em;
                    left: -10%;
                    display: block;
                    height: 10%;
                    max-height: 6px;
                    width: 100%;
                    background: var(--primary-500);
                }
            }
        }
    }



    #labelContent {
        display: flex;
        flex-direction: row;
        position: sticky;
        margin-left: 30%;
        width: fit-content;
        top: 50%;
        transform: translateY(-50%);

        &>* {
            position: relative;
            margin-left: 8em;
        }

        a {
            color: var(--base-500);
        }
    }

    .cacheScroll {
        height: 200vh;
        width: 1px;
        display: block;
        position: absolute;
    }
}


#phoneMenu {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background-color: var(--white);
    pointer-events: none;
    transition: clip-path 0.3s ease-out;
    clip-path: inset(0 100% 0 0);
  
    &.on,
    &.on li {
      pointer-events: auto;
      clip-path: inset(0 0 0 0);
    }
  
    &>section:last-of-type {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    li {
      line-height: normal;
      clip-path: inset(0 100% 0 0);
      transition: clip-path 0.3s ease-out;
      transition-delay: 0.2s;
  
      &:nth-child(2) {
        transition-delay: 0.3s;
      }
  
      &:last-child {
        transition-delay: 0.4s;
      }
    }
  
    ul {
      list-style: none;
      padding: 0;
    }
  
  
  }


  #menuLines {
    display: none;
    width: 40px;
    height: 38px;
    position: fixed;
    top: 0;
    right: 0;
    margin: 20px;
    z-index: 10;
  
    span {
      width: 100%;
      height: calc(100%/3);
      box-sizing: border-box;
      padding: 9% 0;
      display: block;
      background-color: var(--base-500);
      background-clip: content-box;
      position: absolute;
      transition: transform 0.3s ease, background-color 0.3s ease;
  
      &:nth-child(2) {
        transform: translateY(100%);
      }
  
      &:last-child {
        transform: translateY(200%);
      }
  
      &.on {
        &:first-child {
          transform: translateY(100%) rotate(45deg);
        }
  
        &:nth-child(2) {
          transform: translateY(100%) scaleX(0);
        }
  
        &:last-child {
          transform: translateY(100%) rotate(-45deg);
        }
      }
    }
  }
  