.guidesheet .laos_card td:nth-child(2) {
  background-image: url("/projects/laos/assets/card_1.svg");
}

.guidesheet .laos_card td:last-of-type {
  background-image: url("/projects/laos/assets/card_2.svg");
}


#guidesheet {
  .body {
    p {
      margin-bottom: 1.3rem;
      text-align: center;
    }

    justify-content: space-between;

    &>div:last-of-type {
      width: 80%;
    }
  }
}

.tone_1 {
  color: #4fa2f7;
}

.tone_2 {
  color: #ff6a55;
}

.tone_3 {
  color: #70bd0e;
}

.tone_4 {
  color: #e54ff2;
}




#mockups .window {
  margin-bottom: 5%;
}

#laos-userflow {
  .cartography {
    border: none;
  }
}

/*———————————————————————————————————————————————-*/

#features-icons-container {
  height: fit-content;
  gap: 20px;
  flex-basis: 10%;
  padding: 2rem;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 50%);
  justify-items: center;
  align-items: center;

  span {
    width: 100%;
    aspect-ratio: 1/1;
    background-color: white;
    border-radius: 20%;
    cursor: pointer;
    transition: transform 0.3s ease-out;
    transform: scale3d(1, 1, 1);
    background-size: 70%;

    &[data-type="lotus"] {
      box-shadow: 0px 3.90123px 3.90123px rgba(0, 0, 0, 0.25), inset 0px 0px 32.1852px rgba(92, 157, 255, 0.17);
      background-image: url('/projects/laos/assets/lotus.png');
    }

    &[data-type="yin"] {
      box-shadow: 0px 3.90123px 3.90123px rgba(0, 0, 0, 0.25), inset 0px 0px 32.1852px rgba(255, 146, 244, 0.17);
      background-image: url('/projects/laos/assets/yin.png');
    }

    &[data-type="fire"] {
      box-shadow: 0px 3.90123px 3.90123px rgba(0, 0, 0, 0.25), inset 0px 0px 32.1852px rgba(255, 148, 70, 0.17);
      background-image: url('/projects/laos/assets/fire.png');
    }

    &[data-type="chakra"] {
      box-shadow: 0px 3.90123px 3.90123px rgba(0, 0, 0, 0.25), inset 0px 0px 32.1852px rgba(105, 13, 255, 0.17);
      background-image: url('/projects/laos/assets/chakra.png');
    }

    &:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
}

/*———————————————————————————————————————————————-*/


#laos_features {
  height: 90vh;
  max-height: 600px;

  @media (orientation: portrait) {
    max-height: none;
  }
}

#ft_circle_infobox {
  box-sizing: border-box;
  padding: 2rem 4rem;
  max-height: 100%;

  &>.infobox {
    padding-bottom: 20%;
  }

  img {
    border-radius: 15px;
    opacity: 1;
    max-width: 100%;
    margin-top: -15%;
    margin-left: 10%;
    transform: scale(1);
    transition: opacity 0.3s ease;
    z-index: 9;
    box-shadow: -3px 3px 10px rgba(0 0 0 /20%);
  }

}


/*———————————————————————————————————————————————-*/


#keyshape {
  width: 90%;
  flex-basis: 60%;
  justify-content: flex-end;

  img {
    max-width: 100%;
  }

  td {
    width: 25%;
    padding: 3%;
    box-sizing: border-box;
  }
}



#blueprint td {
  height: 30vw;
}


.keyexplain {
  height: 100%;
  padding: 10% 15% !important;
  align-items: center;
  background: #f1f1f1;
  box-shadow: 0 0 3px rgba(0 0 0 /20%) inset;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.keyexplain>* {
  flex-basis: calc(100%/6);
}

.c_color {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 100%;
}


/*———————————————————————————————————————————————-*/


#laos_typo .header {
  padding-left: 20%;

}

#laos_typo .body>section:nth-child(1),
#laos_typo .body section:nth-child(2) {
  flex-basis: 25%;
  flex-shrink: 0;
}



#NotoTypo h2:first-child {

  font-size: 3em;
}


#laos_typo_switch {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &>* {
    font-size: 8em;
    font-weight: normal !important;
    animation-name: popTypo;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    &:first-child {
      animation-name: popTypo;
      position: absolute;
      width: 30%;
      box-sizing: border-box;
      padding: 10px;
    }

  }

  &>h1:last-child {
    animation-name: popTypo, maskTypo_1;
    background: #f5f7f9;
    font-family: sans-serif;
    text-align: center;
    width: 40%;
  }
}





@keyframes popTypo {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.5);
  }

  60% {
    transform: scale(1.5);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes maskTypo_1 {
  0% {
    clip-path: circle(0% at center);
  }

  40% {
    clip-path: circle(100% at center);
  }

  60% {
    clip-path: circle(100% at center);
  }

  70% {
    clip-path: circle(0% at center);
  }

  100% {
    clip-path: circle(0% at center);
  }
}


/*———————————————————————————————————————————————-*/


#laos_layout .body>div:not(#slicer) {
  display: flex;
  flex-direction: row;
  column-gap: 5%;
  width: 100%;
  justify-content: center;

}

#laos_layout .body>div:not(#slicer)>* {
  flex-basis: 50%;
}


#laos_layout .body>#slicer {
  text-align: center;
  margin-top: 15%;
  margin-bottom: 15%;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: none;
}

#slicer {
  cursor: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &>img {
    max-width: 100%;
    position: relative;
    padding: 0;

    &:last-of-type {
      position: absolute;
      clip-path: inset(0 50% 0px 0);
      left: 0;
      //background-image: url("/projects/laos/assets/BW_flashcard.jpg");
      max-width: 101%;
      height: 100%;
    }

    &:first-of-type {
      box-shadow: 0 0 3px rgba(0 0 0 / 20%) inset;
    }
  }

  &>span {
    position: absolute;
    width: 1px;
    display: block;
    background: #f3f3f3;
    height: 100%;
    left: 50%;
    top: 0;
    padding: 0 1px;
    pointer-events: none;
  }


}

.autoSlice {
  cursor: default;
}

.autoSlice>img:last-of-type {
  animation-name: slicer_clippath;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.autoSlice>span {
  animation-name: slicer_left;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes slicer_clippath {
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes slicer_left {
  from {
    left: 0%;
  }

  to {
    left: 100%;
  }
}


/*———————————————————————————————————————————————-*/

#laos_ergonomy {
  &::before {
    background-image: linear-gradient(124.8deg, #F7EAFF -2.73%, #B6E0FE 100.08%)
  }
}

#laos_mantra {
  color: white;

  &::before {
    background-image: url('/projects/laos/assets/mantrabackdrop.jpg');
    background-size: cover;
  }
}

/*———————————————————————————————————————————————-*/

#laos_sidebar {
  list-style: none;
  padding: 0;
  height: 100vh;

}

#laos_sidebar>li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;

  &:nth-of-type(1) section {
    background-color: #4EBAFD;
  }

  &:nth-of-type(2) section {
    background-color: #FF1473;
  }

  &:nth-of-type(3) section {
    background-color: #FFA613;
  }

  &:nth-of-type(4) section {
    background-color: #BF38DE;
  }

  section {
    width: 50%;
    display: grid;
    box-sizing: border-box;
    justify-items: center;
    margin-top: -25%;
    border-radius: 7px;
    transition: height 0.2s ease;
    overflow: hidden;
  }
}


#laos_sidebar .ico {
  width: 100%;
  height: 1.5em;
  display: block;
  margin: 20% 0;
}

#laos_sidebar section .ico:first-of-type {
  margin-top: 80%;
}

.ico_pen {
  background-image: url("/projects/laos/assets/ico/pen.svg");
}

.ico_rand {
  background-image: url("/projects/laos/assets/ico/dice_1.svg");
}

.ico_moon {
  background-image: url("/projects/laos/assets/ico/moon.svg");
}

.ico_pinyin {
  background-image: url("/projects/laos/assets/ico/show_pinyin.svg");
}

.ico_english {
  background-image: url("/projects/laos/assets/ico/english.svg");
}

#laos_sidebar .app {
  padding: 5%;
  border-radius: 33%;
  z-index: 9;
  object-fit: contain;
}


#laos_sidebar section .ico:first-of-type {
  margin-top: 65%;
}

#laos_sidebar section .ico:last-of-type {
  margin-bottom: 30%;
}

/*———————————————————————————————————————————————-*/

#laos_mechanics {
  box-sizing: border-box;
  padding-top: 5%;

}

#laos_mechanics ul {
  list-style: none;
  color: #FFF;
  padding: 0;
  margin-bottom: 10%;
  width: 40%;
  display: none;
}

#laos_mechanics ul>ul {
  border-left: solid white 2px;
  padding-left: 7%;
}

#laos_mechanics ul>li:first-of-type {
  font-size: 110%;
}

#laos_mechanics p {
  padding: 0;
}

#laos_mechanics .icons {
  width: 10px;
  height: 10px;
  display: block;
}

#laos_mechanics>section {
  /*display: grid;*/
  grid-template-columns: 5fr 1fr;
  column-gap: 3%;
  margin-bottom: 5%;
}

#laos_mechanics ul section {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 0.5em;
}

.ico_pointer {
  background-image: url("/projects/laos/assets/ico/pointer.svg");
}

.ico_type {
  background-image: url("/projects/laos/assets/ico/type.svg");
}

.ico_scroll {
  background-image: url("/projects/laos/assets/ico/scroll.svg");
}

.ico_verti {
  background-image: url("/projects/laos/assets/ico/vertical.svg");
}

.ico_grid {
  background-image: url("/projects/laos/assets/ico/grid.svg");
}

.ico_static {
  background-image: url("/projects/laos/assets/ico/static.svg");
}

.ico_drag {
  background-image: url("/projects/laos/assets/ico/drag.svg");
}

.ico_horizontal {
  background-image: url("/projects/laos/assets/ico/horizontal.svg");
}


/*———————————————————————————————————————————————-*/

#laos_worlds {
  position: relative;
  overflow: hidden;
  height: 100vh;
  box-sizing: border-box;
  width: 80%;
  margin-left: 10%;

}

#laos_worlds>img {
  position: absolute;
  top: 0;
  left: 0;
  transition: clip-path 0.3s ease;
}

#laos_worlds>img:last-child {
  clip-path: polygon(0 0,
      calc(25% + 10%) 0,
      calc(25% - 10%) 100%,
      0 100%);

  -webkit-clip-path: polygon(0 0,
      calc(25% + 10%) 0,
      calc(25% - 10%) 100%,
      0 100%);
}

#laos_worlds>img:nth-child(3) {
  clip-path: polygon(calc(25% + 10%) 0,
      calc(50% + 10%) 0,
      calc(50% - 10%) 100%,
      calc(25% - 10%) 100%);

  -webkit-clip-path: polygon(calc(25% + 10%) 0,
      calc(50% + 10%) 0,
      calc(50% - 10%) 100%,
      calc(25% - 10%) 100%);
}

#laos_worlds>img:nth-child(2) {
  clip-path: polygon(calc(50% + 10%) 0,
      calc(75% + 10%) 0,
      calc(75% - 10%) 100%,
      calc(50% - 10%) 100%);

  -webkit-clip-path: polygon(calc(50% + 10%) 0,
      calc(75% + 10%) 0,
      calc(75% - 10%) 100%,
      calc(50% - 10%) 100%);
}

#laos_worlds>img:first-child {
  clip-path: polygon(calc(75% + 10%) 0,
      100% 0,
      100% 100%,
      calc(75% - 10%) 100%);

  -webkit-clip-path: polygon(calc(75% + 10%) 0,
      100% 0,
      100% 100%,
      calc(75% - 10%) 100%);
}

#laos_worlds>img.active {
  clip-path: polygon(0 0,
      100% 0,
      100% 100%,
      0 100%);
  -webkit-clip-path: polygon(0 0,
      100% 0,
      100% 100%,
      0 100%);
}