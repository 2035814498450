.cat {

    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 10vw;
    padding-top: 5vw;
    margin-right: 8%;
    width: 55%;
    transition: transform 0.2s ease;
  
    &>section:first-child>h1 {
      min-height: 10vw;
      margin-right: 20px;
      margin-left: 20px;
      font-size: 6em;
      max-width: 4em;
      pointer-events: none;
      @include squareCursor;
      color: white;
    }
  
    .title {
      color: var(--base-500);
    }
  
    .project-thumbnail {
      float: right;
    }
  
    .linkSlider {
      display: 'inline-block';
      transition: transform 0.6s ease-out, opacity 0.5s ease;
      width: fit-content;
  
      & * {
        cursor: pointer;
      }
    }
  
  }