.block {
    display: block;
}

.vertical {
    flex-direction: column;
}

.centered {
    align-items: center;
    justify-content: center;
}

.horizontal {
    flex-direction: row;
}

.right {
    align-items: flex-end;
}

.hcenter {
    justify-content: center;
}

.vcenter {
    align-items: center;
}

.padleft {
    padding-left: 5%;
}

.padright {
    padding-right: 5%;
}

.t-center{
    text-align: center;
}

.no-margin{
    margin:0;
}

.gap-small{
    gap:24px;
}


.border-light{
    border: solid 1px var(--base-50);
}


.flex {
    display: flex;

    &.f-row {
        flex-direction: row;
    }

    &.f-col {
        flex-direction: column;
    }



    &.f-center {
        align-items: center;
        justify-content: center;

        &-h {
            align-items: center;
        }

        &-w {
            justify-content: center;
        }
    }

    &.f-wrap {
        flex-wrap: wrap;
    }

    &.f-end {
        justify-content: end;
    }

    &.f-start {
        justify-content: start;
    }

    &.f-between {
        justify-content: space-between;
    }

    &.f-around {
        justify-content: space-around;
    }

    &.gap {
        &-xs {
            gap: var(--unit-xs);
        }

        &-s {
            gap: var(--unit-s);
        }

        &-m {
            gap: var(--unit-m);
        }

        &-l {
            gap: var(--unit-l);
        }

        &-xl {
            gap: var(--unit-xl);
        }

        &-2xl {
            gap: var(--unit-2xl);
        }

        &-3xl {
            gap: var(--unit-3xl);
        }

        &-4xl {
            gap: var(--unit-4xl);
        }

        &-5xl {
            gap: var(--unit-5xl);
        }

        &-6xl {
            gap: var(--unit-6xl);
        }
    }
}


.full {

    &-width {
        width: 100%;
    }

    &-height {
        height: 100%;
    }
}