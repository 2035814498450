.label {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    .ico {
      display: flex;
      align-items: center;
    }
  
    .ico img,
    svg {
      width: 24px;
      height: 24px;
      margin: 0;
      vertical-align: middle;
      object-fit: contain;
    }
  
    svg {
      fill: var(--base-500);
    }
  
  }