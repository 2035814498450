.dropdown {

    position: relative;
  
    &.active {
      p svg {
        transform: rotate(-180deg);
      }
  
      ul {
        pointer-events: auto;
      }
    }
  
    ul {
      pointer-events: none;
      list-style: none;
      padding: 0;
      position: absolute;
      width: max-content;
      min-width: 70px;
      padding: 5px 10px;
      z-index: 4;
  
      li {
        box-sizing: border-box;
        padding: 4px 2px;
        transition: color 0.2s ease;
  
        @media(hover:'hover') {
          &:hover {
            color: var(--primary-500);
          }
        }
  
        &:not(:last-of-type) {
          border-bottom: solid 1px var(--card-stroke);
        }
      }
    }
  
    p {
      color: var(--primary-500);
      text-decoration: underline;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      svg {
        fill: var(--primary-500);
        transform: rotate(0deg);
        transition: transform 0.2s ease-out;
      }
    }
  
    p,
    li {
      cursor: pointer;
    }
  }