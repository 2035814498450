@use '../utils';

#letterbox {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-around;
    transition: opacity 0.1s ease;
    perspective: 100px;
    height: initial;
    padding: 3%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include maxWidth();
    left: 50%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    z-index: 1;

    @keyframes updown {
      0% {
        transform: translateY(0);
      }
    
      100% {
        transform: translateY(15px);
      }
    
    }
  
    &,
    & *,
    #textLetterbox>* {
      //pointer-events: none;
    }
  
    #arrowScroll {
      position: absolute;
      bottom: -5%;
      align-self: center;
      animation-name: updown;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
      cursor: pointer;
      pointer-events: auto;
      z-index: 2;
  
      * {
        pointer-events: auto;
      }
    }
  
  
  
  
    #iframewrapper {
      $width: 65vw;
      width: $width;
      aspect-ratio: 16/9;
      //height: calc($width * 9/16);
      max-width: 1250px;
      transition: transform 0.2s ease-out;
      pointer-events: auto;
      z-index: 2;
  
      * {
        cursor: none;
      }
  
      #viewshowreel {
        animation-name: pulse;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 10s;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.25s ease-out;
        transform: scale3d(0, 0, 0);
        z-index: 1;
        //transition: top 0.2s ease-out, left 0.2s ease-out;
        overflow: visible;
        pointer-events: none;
        cursor: none;
        width: 100px;
        height: 100px;
  
        svg {
          fill: var(--primary-500);
          flex-shrink: 0;
          position: absolute;
          transform: translate3d(3px, 0, 0);
        }
  
        img:last-of-type {
          position: relative;
          width: 100%;
          animation-name: rotate;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-duration: 10s;
        }
  
        &::before {
          content: '';
          border-radius: 100%;
          width: 120%;
          height: 120%;
          position: absolute;
          display: block;
          background-color: white;
        }
  
  
        &.active {
          transform: scale3d(1, 1, 1);
        }
  
  
        @keyframes rotate {
          to {
            transform: rotate(-360deg);
          }
        }
  
  
      }
  
      &>div {
        overflow: hidden;
        border-radius: var(--border-radius);
      }
  
      .vimeo {
        .iframePlaceholder {
          box-sizing: border-box;
          overflow: hidden;
        }
      }
  
      a {
        pointer-events: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
      }
  
    }
  
    #toShowreel {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border: none;
  
      &:hover {
        background: none;
      }
  
      z-index: 1;
      margin: 0;
    }
  
    iframe {
      position: relative;
      ;
      box-sizing: border-box;
    }
  
    h5 {
      font-size: 2em;
      margin-bottom: 10px;
  
    }
  
    h2 {
      font-size: 6em;
    }
  
  
    #loadVid {
      position: absolute;
      width: 15vw;
      height: 15vw;
      display: flex;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      align-items: center;
      justify-content: center;
    }
  
  
    #textLetterbox {
      z-index: 0;
      width: max-content;
      max-width: 100%;
      width: 100%;
      height: inherit;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
      flex-direction: column;
      text-align: center;
      gap: 10px;
      transition: transform 0.2s ease-out;
      max-width: 365px;
      max-height: 510px;
  
      .cta {
        pointer-events: auto;
      }
  
      h1 {
        font-family: 'sora';
        font-size: 20px;
        color: var(--primary-500);
      }
  
      h2 {
        font-family: 'murmure';
        font-size: clamp(80px, 7vw, 120px);
        margin: 0;
      }
  
      p {
        margin-top: -10px;
        margin-bottom: 12px;
      }
  
  
      &>header {
        display: flex;
        flex-direction: column;
        align-items: center;
  
      }
  
    }
  
  }
  