label.customInput {

    color: var(--base-500);
    position: relative;
    cursor: text;
    border: solid 1px var(--primary-100);
    padding: 5px 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 42px;
    transition: margin 0.3s ease;
    margin-bottom: 10px;
  
    &~p.errorMessage {
      height: 0em;
      transition: height 0.3s ease;
      display: block;
    }
  
  
    &.focus {
      border-color: var(--primary-500);
  
      .placeholder {
        color: var(--primary-500);
        transform: translate3d(-10px, -27px, 0px) scale3d(0.8, 0.8, 0.8);
      }
  
      &:has(textarea) {
        .placeholder {
          transform: translate3d(-18px, -20px, 0px) scale3d(0.8, 0.8, 0.8);
        }
      }
    }
  
    &.error {
      border-color: #f9600f;
      margin-bottom: 0px;
  
      ~p {
        height: 1em;
  
        &.errorMessage {
          color: #f9600f;
        }
      }
    }
  
  
  
    .placeholder {
      position: absolute;
      pointer-events: none;
      background: var(--white);
      color: var(--primary-100);
      transition: transform 0.3s cubic-bezier(.37, .35, .38, .98);
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  
      &.required {
        &::after {
          content: '*';
          font-weight: 500;
          color: var(--primary-500);
        }
      }
    }
  }