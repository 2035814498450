#showreel {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 100px;
  
    .vimeo {
      padding: 4rem 0 2rem;
      box-sizing: border-box;
    }
  
    #homeButton {
      width: auto;
      position: fixed;
      margin: 1.5% 3%;
      z-index: 8;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  
    div,
    iframe {
      width: 100%;
      height: 100%;
    }
  
    .iframePlaceholder {
      display: flex;
      align-content: center;
      align-items: center;
      height: 100%;
  
      .loadIco {
        width: 50px;
        height: 50px;
      }
    }
  
  
    //iframe{ background: var(--base-500);}
  }