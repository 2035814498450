@use '../core/fonts';

.quotes {

  padding-top: 200px;
  background-color: var(--white);
  background-clip: border-box;
  position: relative;
  overflow: hidden;

  &-slider {
    margin-top: 50px;
    margin-bottom: 100px;
    width: max-content;
    padding-left: var(--unit-4xl);
    transition: transform 0.7s cubic-bezier(.38, -0.01, .2, 1);

    blockquote {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 330px;
      flex: 1;

      h5 {
        padding-left: 10px;
        font-size: 20px;
        line-height: 1.3em;
        border-left: solid 3px var(--primary-500);
        text-wrap: balance;
      }

      .quote-profile {
        display: flex;
        flex-direction: row;
        gap: 8px;

        p {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

      img {
        border-radius: 100%;
      }
    }
  }

  h2 {
    @include fonts.h1;

    svg {
      fill: var(--base-500);
    }
  }

}