@use '../utils';

#contactContainer {

    background-color: var(--white);
    position: relative;
    width: 100vw;
    overflow: hidden;
    background-size: cover;
    background-position: bottom;
    margin: auto;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5% 10%;
    align-content: center;
  
    &>* {
      max-height: 800px;
    }
  
    #signature {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
    }
  
    h1,
    h3 {
      font-family: 'murmure';
      color: var(--primary-500);
      font-size: utils.clamped(70px, 128px);
      font-weight: normal;
    }
  
    h2,
    h4 {
      font-size: medium;
    }
  
    #contactLinks {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
  
      a {
        cursor: pointer;
      }
  
      section {
        margin-top: 20px;
  
        &>*:first-child {
          margin-bottom: 10px;
        }
  
        &>*:last-child {
          margin-left: 40px;
        }
      }
    }
  
    form {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 80%;
      height: fit-content;
      margin: auto;
      box-sizing: border-box;
      border: solid 1px var(--primary-500);
      padding: 30px;
      justify-items: center;
  
  
  
      fieldset {
        border: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
      }
  
      .errorMessage {
        align-self: flex-start;
        margin-bottom: 10px;
      }
  
      label:has(input[type="text"]),
      label:has(input[type="email"]),
      label:has(textarea) {
        width: 100%;
  
        &,
        * {
          cursor: text;
        }
      }
  
      label:has(textarea) {
        height: initial;
        align-items: flex-start;
        flex-basis: 4;
  
        textarea {
          width: 100%;
          height: 100%;
          min-height: 200px;
        }
      }
  
      label:has(input[type="submit"]) {
        align-self: center;
        padding-left: 45px;
        padding-right: 45px;
      }
  
  
    }
  }