#category_menu {
    position: fixed;
    width: 100%;
    top: 1.5%;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 4;
    text-align: center;
  
    .hoverSquare {
      z-index: 0;
    }
  
    a {
      position: relative;
  
      &,
      * {
        transition: color 0s ease
      }
    }
  
    $lp: 1px;
  
    .cacheBold {
      opacity: 0;
      pointer-events: none;
      letter-spacing: $lp;
    }
  
    ul {
      box-sizing: border-box;
      box-shadow: 0 3px 4px #02092b1f;
      border: solid 1px var(--card-stroke);
      padding: 0;
      background: white;
      width: fit-content;
      list-style: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      pointer-events: auto;
      justify-content: center;
      padding: 11px 11px;
      border-radius: 5px;
      box-shadow: 0 3px 4px #02092b26, 0 0 1px #0000003b inset;
  
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: fit-content;
        height: 100%;
        justify-content: center;
        padding: 5px 15px;
        overflow: hidden;
  
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          //border: solid 1px var(--primary-500);
          background-color: var(--primary-50);
          border-radius: 4px;
          box-sizing: border-box;
          transform: scale3d(0, 0, 0);
          transition: transform 0.2s ease-out;
        }
  
        &.active {
          &::before {
            transform: scale3d(1, 1, 1);
          }
  
          a {
            color: var(--primary-500);
  
            small:last-of-type {
              letter-spacing: $lp;
            }
          }
  
          &,
          * {
            font-weight: bold;
          }
        }
      }
    }
  
    img {
      height: 100%;
      position: relative;
    }
  
    a {
      display: flex;
      justify-content: center;
  
      @media(hover:hover) {
        &:hover {
          color: var(--primary-500);
  
          small:last-of-type {
            letter-spacing: $lp;
          }
        }
      }
  
      white-space: nowrap;
      position: relative;
  
      small:last-of-type {
        position: absolute;
        letter-spacing: normal;
        transition: letter-spacing 0.5s cubic-bezier(.48, .12, .07, 1);
        //left: 0;
      }
  
    }
  }