#dotty {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  
    span {
      width: 0.5em;
      height: 0.5em;
      position: relative;
      border-style: solid;
      border-width: 2px;
      margin: 0.3em 0vw;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      transition: background 0.2s ease, border-radius 0.2s ease;
  
      .cross:first-child {
        transform: rotate(45deg);
      }
  
      .cross:last-child {
        transform: rotate(-45deg);
      }
    }
  
    .dotactive {
      background-color: var(--base-500);
    }
  
    .dotactive_white {
      background-color: white;
    }
  
  
  }