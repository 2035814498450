
$depth: 5px;

.slideshow{

  position: relative;

  .slideDots{
    margin-top: 5px;
    span{
      width: 12px;
      height: 12px;
      display: block;
      background: transparent;
      border-width: 2.4px;
      border-style: solid;
      border-color: black;
      float: left;
      margin: 3px 5px;
      margin-left: 0;
      transition: background 0.2s ease;
      &.active, &:hover{ background-color: black; }


    }
  }


  .slideContent{
    overflow: hidden;
    position: relative;
    & > div:not(:first-of-type){
      position: absolute;
      top: 0;
    }
    .slideBar{
      display: block;
      height: 3px;
      position: absolute;
      bottom: 0;

      animation-name: load;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes load{
        from{ width: 0;}
        to{ width:100%; }
      }
    }

  }

  .slideLegend{
    position: relative;
    max-width: 40%;
    bottom: 0;
    right: 0;
    background: white;
    float: right;
    margin-right: -40px;
    margin-top: -30px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 6px 4px 14px rgb(0 0 0 / 10%);
    color: black;
    &:not(:first-of-type){
      position: absolute;
    }

    small{
      z-index: 2;
      position: relative;
    }

    .plane{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      z-index: 2;
      background: white;
    }

    &::before{
      z-index: 0;
      content: '';
      background-color: black;
      width: calc(100% + $depth);
      height: calc(100% + $depth);
      position: absolute;
      top: 0;
      left: 0;

      clip-path: polygon(
        0% 0%,
        calc(100% - $depth) 0%,
        100% $depth, 100% 100%,
        $depth 100%,
        0% calc(100% - $depth )
        );
    }
  }

}


@media only screen and (max-width: 500px){
  .slideshow{
    .slideLegend{
      margin:0;
      bottom: auto;
      right: auto;
      max-width:none;
      margin-top: 2em;
    }
  }
}
