@media only screen and (max-width: 825px) {

  :root {
    --title: 2em;
  }

  body {
    &[data-skin="dark"] {
      #menu {
        &:not(.white) {
          background: #070812;
        }

        &.transparent,
        &.active {
          background: transparent;
        }
      }
    }
  }

  #project .projectWrapper {
    width: auto;
    padding: 40px 15px;
  }

  .casualProject #desc {
    width: 80%;
  }


  .project-thumbnail img {
    max-width: 100%;
    box-sizing: border-box;
  }


  #mock_popup.laos_pannel {
    position: relative;
    margin: auto;
  }

  #homeAbout {
    min-height: 80vh;
    height: fit-content;
    max-height: none;

    .aligncta {
      justify-content: center;
    }

    &>div {
      grid-template-columns: none;
      grid-template-rows: 1fr auto;
      gap: 20px;
      height: fit-content;

      #resume-header {
        padding: 0;
      }

      &>section {
        padding: 5%;
      }

      #resume-portrait {
        max-width: none;
        height: 70vh;
        justify-content: center;
        max-height: 450px;

        &::after {
          width: 90%;
          height: 1px;
          right: auto;
          bottom: calc(-1 * 70px/2);
        }
      }
    }


    #resume-header {
      flex-basis: auto;
    }

  }

  .quotes {
    &>div {
      flex-direction: column;
    }
  }

  #resume-header {
    text-align: center;
  }

  #resume-content {
    max-width: 100vw;

    .resumeSection {
      grid-template-columns: auto;
      gap: 0;

      .sectionHeader {
        width: 100%;
        position: relative;
        top: 0;
      }

      .content {
        &.grid {
          grid-template-columns: auto;
        }
      }
    }

  }

  .inspire {

    &>div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      justify-content: center;

      img {
        flex-basis: 45%;

        &:nth-of-type(2n) {
          margin-top: 0;
        }
      }
    }

  }


  .project-banner {
    padding: 5% 10%;
    height: fit-content;
    position: relative;

    &-content {

      &>div {
        grid-template-columns: none;
        row-gap: 30px;

        section {
          width: 100%;
        }
      }

    }

  }

  #letterbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin: auto;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    //height: -webkit-fill-available;
    justify-content: center;
    gap: 10%;

    #textLetterbox {
      justify-content: center;

      h2 {
        font-size: clamp(80px, 15vw, 100px);
      }

      #arrowScroll {
        display: none;
        animation: none;
      }
    }

  }



  #menu {
    top: 0;
    padding: 3%;
    transition: background 0.2s ease;
    background: transparent;

    &:not(.white) {
      background: #f5f7f9;
    }

    &.transparent,
    &.active {
      background: transparent;
    }

    #bars.active {
      margin-top: 2vw;
    }

    ul {
      clip-path: inset(0 0 100% 0);
      pointer-events: none;
      display: none;
    }

  }

  #resume-portrait {
    width: 100%;
    position: relative;
    height: 55vh;
  }


  #suggest {
    padding-left: 8%;
    padding-right: 8%;

    &>h2 {
      line-height: normal;
    }

    &,
    &>section {
      height: auto;
    }

    &>section {
      width: 100%;
      flex-direction: column;
    }

    img {
      padding: 0;
    }
  }

  #categorySuper {

    display: none;

    .mainTitle {
      width: 100%;
      z-index: 3;
      box-sizing: border-box;
      font-size: 0.6em;
      margin: 0;
      padding: 3%;
      height: 7.5em;
      background: #f3f3f3;
      display: flex;
      justify-content: center;

      section {
        align-items: flex-start
      }

      &::after {
        background-image: none;
      }
    }

  }



  #socials {
    position: relative;
    width: 100%;
    margin: 20px 0;
    margin-bottom: 80px;
    left: 0;

    &>div:last-of-type {
      flex-direction: row-reverse;
      gap: 5px;
    }
  }

  #signature {
    width: 100%;
    margin: 30px 0;
    text-align: center;
    flex-direction: column;

    span {
      visibility: hidden;
    }
  }


  #loaderPage {
    #frameWrapper #underFrame {
      width: 30vw;
      height: 30vw;
    }

    h2 {
      white-space: break-spaces;
      text-align: center;
    }
  }

  #suggest img {
    max-width: none;
  }

  footer>section:first-child {
    display: none;
  }

  .flexblank {
    display: none;
  }

  .player {
    margin-left: 0;
  }


  .cat {
    width: 90%;
    margin: auto;
  }

  #project {
    align-items: center;
    margin-top: 0;

    #desc {
      width: 100%;
    }
  }

  #lastDiv p {

    margin-left: 3%;
    width: 80%;

  }

  #resume-wrapper {
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    padding: 0 30px;
  }


  #juede {
    display: none;
  }

  #resume-settings {

    &::before {
      content: none;
    }

    bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    pointer-events: none;
    padding: 0;
    top: 0;
    z-index: 999991;
    max-width: 100vw;
    background: transparent;
    justify-content: space-between;

    .aligncta {
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: border-box;

      &>* {
        width: initial;
      }

      .cta {
        width: auto;
      }
    }

    &>div {
      padding-top: 4%;
      padding-bottom: 3%;

      &:last-of-type {
        padding-left: 80px;
        padding-right: 80px;
        box-sizing: border-box;
        justify-content: flex-end;
        width: 100%;
        pointer-events: none;

        .dropdown {
          pointer-events: auto;
        }

        &>small {
          display: none;
        }
      }

      &:first-of-type {
        background: var(--white);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  #contactContainer {

    grid-template-rows: auto 2fr;
    grid-template-columns: auto;
    padding-top: 15%;
    gap: 100px;
    height: fit-content;

    #signature {
      position: relative;
    }

    .contactForm {
      margin-top: 0;
      width: 100%;
    }

    #contactLinks {
      justify-content: flex-start;
      gap: 50px;
    }


    &>h1 {
      font-size: 9em;
    }
  }

  /*------CASE STUDY------*/
  section#diffuse_lumino::before {
    top: 0;
  }

  .article {

    .hori,
    .horizontal,
    .body {
      flex-direction: column;
    }

    &.backdrop {
      &::before {
        height: 100%;
        top: 0;
      }
    }


    .parallax {
      flex-direction: column;
      height: max-content;
      gap: 2rem;
      width: 100vw;

      &-picture {
        height: 40vh;
        overflow: hidden;
      }


      &-header {
        position: relative;
        padding-left: 3rem;
        padding-right: 3rem;

        header.card {
          padding: 0;
          background: transparent;
          box-shadow: none;
        }
      }
    }

    #guidesheet {
      .body {
        p {
          text-align: left;
        }
      }
    }

    section.horizontal {
      flex-direction: column;
      gap: 1.3rem;

      &.reverse {
        flex-direction: column-reverse;
      }
    }


    /*Remove desktop/ row layout relevant propreties (hald width, padding)*/

    .half {
      width: 100% !important;
    }

    .compress {
      padding: 0;
    }

    .pointer {
      display: block;
      margin-bottom: 1.3rem;

      span {
        display: none;
      }

      &>br {
        display: none;
      }
    }

    .blank {
      height: 10rem;
    }

    .hidtablet {
      display: none;
    }

    .padleft {
      padding-left: 0;
    }

    .padright {
      padding-right: 0;
    }

    .tableGroup {
      flex-direction: column;
    }

  }

  .musicWave {
    left: -7vw;
    background-position-y: 75%;
  }

  #sidemenu {
    margin-bottom: 40px;
  }

  #dirpath {
    margin-top: 40px;
  }

  #features-icons-container {
    justify-content: center;
    $size: 30vw;
    grid-template-columns: $size $size !important;
    grid-template-rows: $size $size !important;
  }

  .projectWrapper {
    #diffuse_lumino.backdrop {
      &::before {
        top: 0;
        height: 100%;
      }
    }
  }

  #kinoji_first_mockup {
    margin-top: 10%;
  }

  article.persona {
    flex-direction: column;
    width: 90vw;

    header {
      max-width: none;
      border-radius: 10px;
    }
  }

}