#homeAbout {
    height: max-content;
    position: relative;
    box-sizing: border-box;
    padding: 0 5%;
    margin-top: 6%;
    margin-bottom: 3%;
    max-height: 800px;
    background: var(--white);
    @include maxWidth();
  
  
    &::before {
      $height: 200px;
      content: '';
      position: absolute;
      pointer-events: none;
      width: 100%;
      left: 0;
      height: $height;
      background: linear-gradient(0deg, #e0e6ed00 0%, var(--white) 83%);
      background: linear-gradient(180deg, rgba(224, 230, 237, 0) 0%, var(--white) 83%);
      top: calc(-1 * $height);
    }
  
    &>div {
      $gap: 50px;
      height: 100%;
      gap: $gap;
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 1000px;
      margin: auto;
  
      #resume-header {
        padding-right: 10%;
      }
  
      &>#resume-portrait {
        max-width: 50vw;
        position: relative;
        width: 100%;
        height: auto;
        aspect-ratio: 3/4;
        top: 0;
        display: flex;
        align-items: center;
  
      }
  
  
    }
  
  
    /*&::before{
      content: '';
      position: absolute;
      width: 100vw;
      height: 30%;
      display: block;
      left: 0;
      top: -30%;
      background: linear-gradient(0deg, var(--white), #f5f7f900 );
    }*/
  
  }