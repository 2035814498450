#suggest {
    //main
  
    @include frameMargin();
    width: 100%;
    height: 105vh;
    padding-left: 12%;
    padding-right: 12%;
    background: var(--white);
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-origin: border-box;
    float: right;
  
    #signature {
      justify-content: center;
      opacity: 0.5;
      bottom: 0;
    }
  
    #socials {
      left: 0;
      position: relative;
      margin: 20px auto;
      margin-bottom: 0;
      margin-top: 90px;
    }
  
    .project-thumbnail {
      min-height: 50vh;
      max-height: 200px;
      width: 100%;
      height: 100%;
  
      img {
        padding: 0;
        overflow: hidden;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: none;
        max-width: none;
        position: absolute;
        top: 0;
      }
  
      &:hover {
        &::before {
          background-color: var(--primary-500);
        }
  
        header.project-desc>* {
          clip-path: inset(0 0 0 0);
        }
      }
  
  
    }
  
  
  
    &>h2 {
      line-height: 2em;
      margin-left: 0%;
      margin-bottom: 20px;
    }
  
    &>section {
      display: flex;
      gap: 10px;
  
      &>.linkSlider {
        box-sizing: border-box;
        flex-basis: 50%;
      }
    }
  
  
  
  }