#loaderPage {
    position: fixed;
    z-index: 990990;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
  
    .backPlanes {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100vw;
      height: 100vh;
      background-color: var(--primary-500);
      transition: background 0.5s ease;
  
      &:last-of-type {
        background-color: var(--base-900);
  
        &>img {
          transform: scale(1);
          animation-name: zoomBkg;
          animation-delay: 0.5s;
          animation-duration: 10s;
          animation-fill-mode: forwards;
          height: 100%;
          width: 100%;
          object-fit: cover;
          opacity: 0.4;
  
          @keyframes zoomBkg {
            to {
              transform: scale(1.3);
            }
          }
        }
      }
    }
  
    h2 {
      font-family: 'murmure';
      font-weight: normal;
      background-image: linear-gradient(0deg, var(--white), transparent);
      background-position-y: 50vh;
      color: transparent;
      -webkit-text-fill-color: transparent;
      position: absolute;
      font-size: clamp(30px, 40vw, 500px);
      user-select: none;
      margin-bottom: -0.2em; //compenser le lineheight
      -webkit-background-clip: text;
      background-clip: text;
      white-space: nowrap;
      -webkit-text-stroke-color: var(--white);
      -webkit-text-stroke-width: 0.5px;
      background-position-y: 100vh;
  
    }
  
    #loaderPercent {
      display: none;
      position: absolute;
      bottom: 10vh;
    }
  
    #frameWrapper {
      position: relative;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  
      #underFrame {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8vw;
        height: 8vw;
  
        .looper {
          width: 40%;
          height: 40%;
        }
      }
    }
  
    //main frame
  
    #loadFrame {
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
  
      @keyframes sinScale {
        from {
          transform: scale(1);
        }
  
        to {
          transform: scale(0.8);
        }
      }
  
      @include yoyoAnim(sinScale, 2s);
  
      animation-name: none;
  
      #frameCache {
        stroke-width: 26px;
        fill: transparent;
        position: absolute;
        clip-path: url(#frameClip);
      }
  
      #frameClip {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
  
  
        @keyframes rotatePercent {
          to {
            transform: rotate(360deg);
          }
        }
      }
  
    }
  
  }