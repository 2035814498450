

$xtragradient: linear-gradient(104.68deg, #132534 0.98%, #050101 107.45%);


#xtraLogo_introduce{
    &::before{
        background-image: $xtragradient;
    }
    padding-top: 5%;
    padding-bottom:5%;

    img[alt='xtra logo']{
        width: 600px;
        margin: auto;
        margin-top: 100px;
        margin-bottom: 60px;
    }

    hr{
        border: solid 1px #444d6660; 
    }

    & > .body{
        padding-bottom: 100px;
    }
}


#xtra_map{
    img{ width: 700px; }
    &::before{
        background-image: radial-gradient(circle at bottom, #132534 30.98%, #050101 97.45%);
        background-size: cover;
        background-position: center;
    }
}