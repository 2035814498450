@charset "UTF-8";
@use 'utils';
@use './core/fonts';


/*values*/
$frameMargin: 45px;

/*cube*/
$depth: 10px;
$smallDepth: 5px;


html::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

body::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

$arrowDist: 10px;

@keyframes moveArrowX {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  50% {
    transform: translateX(calc($arrowDist/2));
    opacity: 1;
  }

  100% {
    transform: translateX($arrowDist);
    opacity: 0;
  }
}

@keyframes moveArrowY {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(calc($arrowDist/2));
    opacity: 1;
  }

  100% {
    transform: translateY($arrowDist);
    opacity: 0;
  }
}


@mixin ctashadow {
  box-shadow: var(--shadow-elevation-medium);

}

@mixin animArrow($direction: 'x') {

  @if $direction =='x' {
    animation-name: moveArrowX;
  }

  @else {
    animation-name: moveArrowY;
  }

  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:first-of-type {
    animation-delay: 0.15s;
  }


}

@mixin mainFont {
  font-family: 'Sora';
}

@mixin frameMargin() {
  box-sizing: border-box;
  padding: 40px 45px;
}

@mixin squareCursor() {
  //cursor: url('../assets/cursor.png'), default;
}

@mixin skew() {
  transform: skewY(-12deg);
}

@mixin setWindowColor() {

  @each $name,
  $color in $mapColor {
    $i: index($mapColor, $name $color);

    &>span:nth-of-type(#{$i}) {
      background-color: $color !important;
    }
  }
}

@mixin yoyoAnim($name, $duration) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-direction: alternate;
}


@mixin activeCube($depth) {
  transform: translate(calc(-1 * $depth), calc(-1 * $depth));

  &::before {
    width: calc(100% + $depth);
    height: calc(100% + $depth);
  }
}

@mixin maxWidth {
  max-width: 1920px;
  margin: auto;
}

$mapColor: (
  'red': var(--primary-500),
  'blue':var(--primary-500),
  'purple':var(--primary-500),
  'yellow':var(--primary-500)
);

$mapFilterIndex: (
  1: (color:var(--primary-500), filter: url(#r)),
  2: (color:var(--primary-500), filter: url(#b)),
  3: (color:var(--primary-500), filter: url(#p)),
  4: (color:var(--primary-500), filter: url(#o))
);

$mapFilter: (
  (color: var(--primary-500), filter: url(#r)),
  (color: var(--primary-500), filter: url(#b)),
  (color: var(--primary-500), filter: url(#p)),
  (color: var(--primary-500), filter: url(#o))
);

@function filterFromColor($color) {
  @each $obj in $mapFilter {
    @if map-get($obj, color)==$color {
      @return map-get($obj, filter);
    }
  }
}

* {
  font-size: 1em;
  margin: 0;
}

ul {
  list-style: square;
}

u {
  text-underline-offset: 0.2em;
}


p,
li {
  @include squareCursor;
  font-weight: normal;
}

b {
  font-weight: 500;
}

small {
  font-size: 0.8em;
}

a {
  color: var(--base-500);
  text-decoration: none;
  line-height: 1.2em;
}

form {
  @include mainFont;
}

h1,
.h1 {
  font-family: 'murmure';
  font-size: 11em;
}

h2 {
  font-size: 3em;
  font-weight: 500;
}

h3 {
  font-size: 3em;
  font-weight: bold;
}

h4 {
  @include mainFont;
  font-size: 1.5em;
  font-weight: bold;
}

h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 2.5rem;
}

button,
input,
label,
a {

  &,
  & * {
    cursor: pointer;
  }
}

mark {
  // background-color: var(--primary-500);
  // color:var(--white);

  background-color: var(--primary-100);
}

.container {
  max-width: 1440px;
  margin: auto;
  padding: 0 100px;
  box-sizing: border-box;
}

.purple {
  color: var(--primary-500);
}

.whitecolor {
  color: var(--white);
}

.color {
  &-primary {
    color: var(--primary-500);
  }
}

.aligncta {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  width: fit-content;

  &>* {
    flex-shrink: 0;
    flex-grow: 1;
  }
}


input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input {
  border: none;
  background: white;
  display: none;

  &:checked+.cube {
    @include activeCube($smallDepth);
  }

  &:checked+.cta {
    &::before {
      opacity: 1
    }

    ;

    &,
    * {
      color: var(--white);
    }
  }
}


.zhg {
  display: inline-block;
}

b .zhg {
  font-weight: bold;
}


.round {
  border-radius: var(--border-radius);
  overflow: hidden;
}




@mixin setDarkSkin() {
  background: var(--base-900);
  color: var(--white);

  a {
    color: var(--white);
  }

  #windowIco span {
    background-color: var(--white) !important;
  }

  #menu {
    p {
      color: var(--white);
    }

    #homeButton {
      svg {
        fill: var(--white);
      }
    }

    #NLogo {
      p {
        color: var(--white);
      }

      svg {
        fill: var(--white);
      }
    }
  }

  .discrete {
    color: var(--white);
  }

  #loaderPage {

    .backPlanes:last-of-type,
    #loadPercent {
      background-color: var(--base-900) !important;
    }
  }

  #bars span {
    background: var(--white) !important;
  }

  #bars.active span {
    background: var(--base-500) !important;
  }

  #project {
    background: var(--base-900);
  }

  #suggest {
    background: var(--base-900);

    .project-thumbnail {
      box-shadow: none;
    }
  }

  .vimeo {
    background: var(--base-900);
  }

  .article {
    .pointer {
      span {
        background-color: var(--white);

        &::before {
          background: var(--white);
        }
      }
    }
  }

  .mainTitle {
    &::after {
      background-image: linear-gradient(0deg, var(--base-900), #f3f3f300 30%, #f3f3f300 90%, var(--base-900));
    }
  }

  .gallery {
    section {
      border: solid 0.5px #282828;
    }

  }

  .socialsIcons {
    .ico {
      span {
        background-color: var(--white);
      }
    }
  }

}

@mixin setWhiteMenu() {
  #bars:not(.active) span {
    background: var(--white) !important;
  }
}

@mixin setDefaultSkin() {
  background: var(--white);
  color: var(--base-500);

  #windowIco span {
    background-color: var(--base-500);
  }

  #menu p {
    color: var(--base-500);
  }

  .discrete {
    color: var(--base-400);
  }

  #loaderPage {

    /*.backPlanes:last-of-type,*/
    #loadPercent {
      background-color: var(--white);
    }
  }
}


body {
  @include squareCursor;
  overflow-x: hidden;
  background: var(--white);
  color: var(--base-500);
  transition: background 0.7s ease;
  text-rendering: geometricPrecision !important;
  -webkit-font-smoothing: antialiased;
  @include mainFont;

  &[data-skin='dark'] {
    @include setDarkSkin;
  }

  &[data-skin='default'] {
    @include setDefaultSkin;
  }

  &[data-skin='whiteMenu'] {
    @include setWhiteMenu;
  }
}

footer {
  width: 100vw;
  display: flex;
  padding: 3vw 6vw;
  min-height: 25vh;
  box-sizing: border-box;
  justify-content: space-evenly;
  @include squareCursor;
  color: var(--base-500);

  &>section::before {
    content: '';
    display: block;
    height: 2px;
    width: 120%;
    background: var(--base-500);
    margin-bottom: 20px;
    margin-left: -10%;
  }

  a {
    line-height: 23px;
    text-decoration: none;
    cursor: pointer;
  }

  .ico {
    margin: 0 3px;

    &:hover::before {
      transform: scale(1);
      border-radius: 0;
    }
  }

  #footer_social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}






#signature {
  display: flex;
  flex-direction: row;
  margin: 30px;
  justify-content: center;
  align-items: center;

  span {
    height: 1px;
    background: var(--base-400);
    display: block;
    width: 30px;
    margin: 0 10px;
  }
}


.hoverSquare {
  //main
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: -45%;
  left: -30%;

  &,
  &>* {
    cursor: pointer;
  }

  span {
    border-radius: calc(var(--border-radius)/2);
    width: 100%;
    height: 100%;
    display: block;
    border: solid 1px var(--primary-500);

    &:last-of-type {
      position: absolute;
      top: 30%;
      left: 30%;
    }
  }
}


.ico {
  //main
  display: inline-block;
  position: relative;
  flex-shrink: 0;

  img {
    width: 20px;
    height: 20px;
    margin: 5px;
    vertical-align: top;
    object-fit: contain;
  }


  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: -2;
    border-radius: 20em;
    transition: transform 0.2s ease-out, border-radius 0.2s ease;
    transform: scale(0);
  }
}

.highlight::before {
  content: '';
  width: 3em;
  height: 0.5em;
  display: block;
  position: absolute;
  background: #ff5252;
  top: 50%;
  z-index: 0;
}


iframe.normal {
  height: 50vh;
}

.justifyGallery {
  position: relative;
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.discrete {

  &,
  & * {
    color: var(--base-400);
  }

  ;
}



/* --------- POP / REMOVE back button ----------*/
.surligneur {
  position: relative;
  @include squareCursor;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0%;
    margin-left: -6px;
    height: 10%;
    left: 0;
    bottom: -3px;
    background-color: var(--primary-500);
    z-index: -2;
    transition: width 0.2s ease;
    max-height: 10px;
  }

  &:hover::before {
    width: 100%;
  }

  &.active::before {
    width: 100%;
  }

  &.nomargin::before {
    margin-left: 0;
  }

  &.overlay::before {
    transition: height 0.1s ease-in;
  }

  &.overlay:hover::before {
    height: 100%;
  }
}

span.dottySeparator {
  margin: 0 10px;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 1px;
  background-color: var(--primary-500);
}

span.lineSeparator {
  display: block;
  margin: 0 7px;
  width: 1px;
  height: 30px;
  background-color: var(--primary-50);
}




#project {
  //Main

  background: var(--white);
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  margin-top: 60vh;

  .projectWrapper {

    .vimeo {
      .iframePlaceholder {
        .picPlaceholder {
          object-fit: cover;
        }
      }
    }

    &>div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }



}


#projects {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include maxWidth();
  margin-top: 130vh;
}





.contentHeader,
.contentBody {
  padding: 0 8px;
  box-sizing: border-box;
}





.picLink {
  position: relative;
  cursor: pointer;

  img {
    max-height: none !important;
  }

  span {
    opacity: 0;
    transition: opacity 0.3s ease;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--white);
    width: 30px;
    height: 30px;
    margin: 10px;
  }

  &:hover {
    span {
      opacity: 0;
    }
  }
}



textarea {
  font-family: 'Sora', sans-serif;
}

.rotate {
  animation-name: rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@media screen and (orientation:portrait) {


  section.not-found {
    flex-direction: column-reverse;
  }

  #resume {

    &-grid {
      grid-template-rows: auto auto;
      grid-template-columns: none;
      justify-items: center;
      padding: 0;
      width: 100%;
    }

    &-wrapper {
      width: 100%;
      position: relative;
    }

    &-portrait {
      height: auto;
    }

    &-content {
      margin-top: 0;
      grid-template-columns: repeat(2, 1fr);

      #signature {
        padding: 0;
        position: relative;
        margin-top: 90px;
      }
    }

    &jus {
      width: 100%;
      justify-content: space-evenly;
    }

    &-header {
      p {
        white-space: normal;
      }
    }

  }


  #resume-portrait {
    width: 100%;
    position: relative;
    right: 0;
    pointer-events: none;
  }

  #laos_features {
    height: fit-content !important;
  }

  #laos_typo {
    .body {
      section:nth-child(2) {
        margin: 10% 0;
      }
    }
  }

}