@use '../utils';
@use '../core/fonts';

#categorySuper {

    top: 0;
    position: fixed;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100%/3);
    pointer-events: none;
  
    @media only screen and (min-width: 2300px) {
      left: 8vw;
    }
  
  
    #zhongwenName {
      position: absolute;
      height: max-content;
      margin-top: 10%;
  
      &>* {
        font-size: clamp(70px, 9vw, 150px);
        font-family: 'murmure';
        opacity: 0.4;
        color: transparent;
        -webkit-text-stroke: 1px var(--base-500);
        line-height: 1.1em;
        margin: 0;
        @media only screen and (min-width: 1920px) {
          font-size: clamp(80px, 13vw, 160px);
        }
      }
    }
  
    .mainTitle {
      pointer-events: none;
      @include squareCursor;
      transform-origin: left;
      height: calc(100%/3);
      position: relative;
      clip-path: inset(10% 0% 35% 0%);
      //@each $name, $color in $mapColor{
      //  h2[data-color='#{$name}']{ color:$color !important;  }
      //}
  
  
      .underline {
        height: 6px;
        width: 100%;
        display: block;
        background: var(--base-500);
        transition: width 0.3s ease;
        margin-left: -7%;
      }
  
  
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: background 0.2s ease;
        //background-image: linear-gradient(0deg, var(--white), #f3f3f300 30%, #f3f3f300 90%, var(--white));
      }
  
      h1,
      h2 {
        transition: color 0.2s ease;
        color: var(--base-500);
      }
  
      * {
        $title: 7.5vw;
        font-family: 'murmure';
        float: left;
        text-transform: none;
        text-align: center;
        @include fonts.h1;
  
        @media only screen and (min-width: 2300px) {
          font-size: utils.clamped(70px, 160px);
        }
        
      }
  
  
      section {
        position: relative;
        display: grid;
        grid-template-rows: repeat(3, 2em); //hardcode
        top: 0;
        /*width: calc($title/2);*/
        align-items: center;
        white-space: pre-wrap;
      }
  
      section>h1:first-of-type {
        left: -100%;
      }
  
    }
  
  
  }