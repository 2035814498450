#resume-portrait {

    h2 {
      font-family: 'murmure';
      writing-mode: vertical-lr;
      font-size: clamp(50px, 3.2vw, 100px);
      position: absolute;
      margin: 3% 1%;
      color: var(--primary-500);
      z-index: 0;
  
      &:first-of-type {
        top: 0;
        left: 0;
      }
  
      &:last-of-type {
        bottom: 0;
        right: 0;
      }
    }
  
    canvas {
      position: relative;
    }
  }