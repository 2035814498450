#toShowreel {
    position: absolute;
    z-index: 2;
    bottom: 0;
    margin: 85px 8%;
    color: white;
    text-transform: uppercase;
    transition: color 0.2s ease, background 0.2s ease;
    border: solid white 2px;
    padding: 0.5% 1%;
  
    * {
      color: white;
    }
  
    &:hover {
      background: white;
  
      * {
        color: var(--base-500);
      }
    }
  }